import {Button} from 'components/Button';
import {hslToRgb, rgbToHsl} from 'definitions/Color';
import Simulation from 'definitions/Simulation';
import Size from 'definitions/Size';
import React, {ReactElement, useEffect, useRef} from 'react';
import {setConfirmRemoveVersionId} from 'redux/applicationReducer';
import {duplicateSimulation, simulationActivated} from 'redux/artReducer';
import {useAppDispatch} from 'redux/store';

interface Props{
	simulation: Simulation,
	isActive: boolean,
}

export default function SimulationItem(props: Props): ReactElement {
	//console.log("SimulationItem")
	const COMPONENT_HEIGHT = 105;
	
	const dispatch = useAppDispatch();
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const canvasDataInitialRef = useRef<ImageData|null>(null);
	const layers = props.simulation.layers;
	const size: Size = {w: props.simulation.width * COMPONENT_HEIGHT / props.simulation.height, h: COMPONENT_HEIGHT}
	
	const duplicate = (e: React.MouseEvent<HTMLElement>) => {
		dispatch(duplicateSimulation());
	}
	
	const onClick = () => {
		if (props.isActive) return;
		
		dispatch(simulationActivated(props.simulation.id));
	}
	
	useEffect(() => {
		const image = new Image();
		image.onload = () => {
			const context = canvasRef.current?.getContext('2d');
			const tempImgContext = document.createElement("canvas").getContext("2d");
			if (!tempImgContext || !context) return;
			
			tempImgContext.canvas.width = props.simulation.width;
			tempImgContext.canvas.height = props.simulation.height;
			tempImgContext.drawImage(image, 0, 0, props.simulation.width, props.simulation.height);
			
			canvasDataInitialRef.current = tempImgContext.getImageData(0, 0, props.simulation.width, props.simulation.height);
			
			drawItem();
		}
		
		image.src = props.simulation.imgData;
	}, [props.simulation.imgData])
	
	const drawItem = () => {
		if (!canvasDataInitialRef.current) return;

		let dataArray: Uint8ClampedArray = new Uint8ClampedArray(props.simulation.width * props.simulation.height * 4);
		let i, j, avgSum, avgCnt, avgL;
		let color, pointColor, newColor;
		
		for (j = 0; j < layers.length; j++) {
			let layer = layers[j];
			if (!layer.mask || !layer.color) continue;

			/** avg lightness **/
			avgSum = avgCnt = 0;
			for (i = 0; i < layer.mask.data.length; i += 1) {
				if (layer.mask.data[i] > 0) {
					pointColor = rgbToHsl(canvasDataInitialRef.current.data[i*4], canvasDataInitialRef.current.data[i*4+1], canvasDataInitialRef.current.data[i*4+2]);
					avgSum += pointColor.l;
					avgCnt++;
				}
			}
			avgL = avgSum / avgCnt;
			
			color = rgbToHsl(layer.color.r, layer.color.g, layer.color.b);
			for (i = 0; i < layer.mask.data.length; i += 1) {
				if (layer.mask.data[i] > 0) {
					pointColor = rgbToHsl(canvasDataInitialRef.current.data[i*4], canvasDataInitialRef.current.data[i*4+1], canvasDataInitialRef.current.data[i*4+2]);
					newColor = hslToRgb(color.h, color.s, pointColor.l - avgL + color.l);
					dataArray[i*4] = newColor.r;
					dataArray[i*4 + 1] = newColor.g;
					dataArray[i*4 + 2] = newColor.b;
					dataArray[i*4 + 3] = 255;
				}
			}
		}
		const context = canvasRef.current?.getContext('2d');
		const tempContext = document.createElement("canvas").getContext("2d");
		if (!context || !tempContext) return;
		
		tempContext.canvas.width = props.simulation.width;
		tempContext.canvas.height = props.simulation.height;
		tempContext.putImageData(new ImageData(dataArray, props.simulation.width, props.simulation.height), 0, 0);
		
		context.canvas.width = size.w;
		context.canvas.height = size.h;
		context.drawImage(tempContext.canvas, 0, 0, size.w, size.h);
	}
	
	useEffect(() => {
		drawItem();
	}, [layers])
	
	return (
		<div className={`SimulationList__SimulationItem ${props.isActive ? 'SimulationList__SimulationItem--active' : ''}`}>
			<img src={props.simulation.imgData} alt="" />
			<canvas ref={canvasRef} width={size.w} height={size.h}  onClick={onClick}/>
			{props.isActive &&  <div className="SimulationList__SimulationItem__Border"></div>}
			
			<Button className="SimulationList__SimulationItem__Button--delete Button--small" color={'white'} onClick={() => {dispatch(setConfirmRemoveVersionId(props.simulation.id))}}>
				<svg width="15" height="15"><use xlinkHref="#ico_trash" /></svg>
			</Button>
			{props.isActive && <Button className="SimulationList__SimulationItem__Button--copy Button--small" color={'white'} onClick={duplicate}>
				<img src="ico_copy.png" alt="" />
			</Button>}
		</div>
	)
}

