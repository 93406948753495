
export default interface Color {
	r: number,
	g: number,
	b: number,
	name?: string,
}

export interface RgbColor {
	r: number,
	g: number,
	b: number,
}

export interface HslColor {
	h: number,
	s: number,
	l: number,
}

export function rgbToHsl (r:number, g:number, b:number): HslColor {
	r /= 255;
	g /= 255;
	b /= 255;
	
	const max = Math.max(r, g, b);
	const min = Math.min(r, g, b);
	let h = 0,
		  s = 0,
		  l = (max + min) / 2;
	
	if (max === min) {
		h = s = 0;
	} else {
		const d = max - min;
		s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
		
		switch (max) {
			case r: h = (g - b) / d + (g < b ? 6 : 0); break;
			case g: h = (b - r) / d + 2; break;
			case b: h = (r - g) / d + 4; break;
		}
		
		h /= 6;
	}
	
	return {h: h, s: s, l: l};
}

function hue2rgb(p:number, q:number, t:number): number {
	if (t < 0) t += 1;
	if (t > 1) t -= 1;
	if (t < 1/6) return p + (q - p) * 6 * t;
	if (t < 1/2) return q;
	if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
	return p;
}

export function hslToRgb(h: number, s: number, l: number): RgbColor {
	var r, g, b;
	
	if (s === 0) {
		r = g = b = l;
	} else {
		var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
		var p = 2 * l - q;
		
		r = hue2rgb(p, q, h + 1/3);
		g = hue2rgb(p, q, h);
		b = hue2rgb(p, q, h - 1/3);
	}
	
	return {r: r * 255, g: g * 255, b: b * 255};
}