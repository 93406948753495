import {createSlice, Dispatch} from '@reduxjs/toolkit';
import ArtState from 'definitions/ArtState';
import Bounds from 'definitions/Bounds';
import Mask, {concatMasks, createMask, gumMask} from 'definitions/Mask';
import {PaintMethod} from 'definitions/PaintMethod';
import Sampler, {getDefaultColor} from 'definitions/Sampler';
import Simulation from 'definitions/Simulation';
import SimulationLayer from 'definitions/SimulationLayer';
import {findById, findIndexById, parseImageHeader, removeItemById} from 'definitions/Utils';
import {RootState} from 'redux/store';

const initialState: ArtState = {
	isColorSamplerListOpen: false,
	tool: 'brush',
	moveMode: false,
	borderMode: true,
	simulations: [],
}

const artSlice = createSlice({
	name: 'Art',
	initialState,
	reducers: {
		openColorSamplerList: (state: ArtState) => {
			state.isColorSamplerListOpen = true;
		},
		closeColorSamplerList: (state: ArtState) => {
			state.isColorSamplerListOpen = false;
		},
		setSampler: (state:ArtState, action) => {
			state.samplerId = action.payload.samplerId;
			state.sampler = action.payload.sampler;
		},
		clearSampler: (state:ArtState) => {
			state.samplerId = undefined;
			state.sampler = undefined;
		},
		setTool: (state: ArtState, action) => {
			state.tool = action.payload;
		},
		setMoveMode: (state: ArtState, action) => {
			state.moveMode = action.payload;
		},
		setBorderMode: (state: ArtState, action) => {
			state.borderMode = action.payload;
		},
		
		projectLoaded: (state: ArtState, action) => {
			const simulations: Simulation[] = action.payload;
			/** version patch - add missing property in older saved projects */
			simulations.map((sim) => sim.layers.map((layer) => layer.paintMethod = layer.paintMethod ?? PaintMethod.INTERPOLATE));
			/** */

			state.simulations = simulations;

			const sim = state.simulations[0];
			state.activeSimulationId = sim.id;
			state.activeLayerId = sim.layers[0].id;
			state.hilitedLayerId = sim.layers[0].id;
		},
		
		simulationCreated: (state: ArtState, action) => {
			state.simulations.push(action.payload);
		},
		simulationActivated: (state: ArtState, action) => {
			state.activeSimulationId = action.payload;
			state.activeLayerId = state.activeSimulationId ? findById(state.activeSimulationId, state.simulations).layers[0].id : null;
			state.hilitedLayerId = state.activeLayerId;
		},
		simulationRemoved: (state:ArtState, action) => {
			state.simulations = removeItemById(action.payload, [...state.simulations]);
		},
		
		setLayerColor: (state: ArtState, action) => {
			if (!state.activeSimulationId || !state.activeLayerId) return;
			
			let simulation = findById(state.activeSimulationId, state.simulations);
			let layer = findById(state.activeLayerId, simulation.layers);
			
			layer.color = action.payload;
			layer.samplerId = state.samplerId;
		},
		
		setLayerName: (state: ArtState, action) => {
			if (!state.activeSimulationId) return;
			
			let simulation = findById(state.activeSimulationId, state.simulations);
			let layer = findById(action.payload.layerId, simulation.layers);
			
			layer.name = action.payload.name;
		},

		setLayerPaintMethod: (state: ArtState, action) => {
			if (!state.activeSimulationId) return;

			let simulation = findById(state.activeSimulationId, state.simulations);
			let layer = findById(action.payload.layerId, simulation.layers);

			layer.paintMethod = action.payload.paintMethod;
		},

		setLayerMask: (state: ArtState, action) => {
			if (!state.activeSimulationId || !state.activeLayerId) return;
			
			let simulation = findById(state.activeSimulationId, state.simulations);
			const layers = [...simulation.layers];
			const layer = findById(state.activeLayerId, layers)
			layer.mask = action.payload;
			
			simulation.layers = layers;
		},
		initCanvasData: (state: ArtState, action) => {
			state.initialDataArray = action.payload;
		},
		setLayerActive: (state: ArtState, action) => {
			state.activeLayerId = action.payload;
			state.hilitedLayerId = action.payload;
		},
		setLayerHilited: (state: ArtState, action) => {
			state.hilitedLayerId = action.payload;
		},
		addLayer: (state: ArtState) => {
			if (!state.activeSimulationId) return;
			
			const oldSimulation = findById(state.activeSimulationId, state.simulations);
			const index = findIndexById(state.activeSimulationId, state.simulations);
			const newId = Date.now();
			
			let simulation = {
				...oldSimulation,
				layers: [...oldSimulation.layers,	{
					id: newId,
					color: getDefaultColor(state.sampler),
					isVisible: true,
					samplerId: state.samplerId,
					paintMethod: PaintMethod.INTERPOLATE,
				} as SimulationLayer]
			};
			
			state.simulations[index] = simulation;
			state.activeLayerId = newId;
			state.hilitedLayerId = newId;
		},
		removeLayer: (state: ArtState, action) => {
			if (!state.activeSimulationId || !state.activeLayerId) return;
			
			let simulation = findById(state.activeSimulationId, state.simulations);
			
			const layerId = action.payload;
			const layers = [...simulation.layers];
			
			if (state.activeLayerId === layerId) {
				const activeIndex = findIndexById(state.activeLayerId, layers);
				const newIndex = activeIndex === 0 ? activeIndex + 1 : activeIndex - 1;
				
				state.activeLayerId = layers[newIndex].id;
				state.hilitedLayerId = layers[newIndex].id;
			}
			
			simulation.layers = removeItemById(layerId, layers);
		},
		removeAllLayers: (state: ArtState) => {
			if (!state.activeSimulationId || !state.activeLayerId) return;
			
			const activeSimulation = findById(state.activeSimulationId, state.simulations);
			const index = findIndexById(state.activeSimulationId, state.simulations);
			const layer = findById(state.activeLayerId, activeSimulation.layers);
			const newId = Date.now();
			
			let simulation = {
				...activeSimulation,
				layers: [{id: newId, color: layer.color, isVisible: true, paintMethod: PaintMethod.INTERPOLATE} as SimulationLayer]
			};
			
			state.simulations[index] = simulation;
			state.activeLayerId = newId;
			state.hilitedLayerId = newId;
		}
	}
});

export const createSimulation = (imgData: string, width: number, height: number) => (dispatch: Dispatch, getState: () => RootState): number => {
	const simulation:Simulation = {
		imgData: imgData,
		imgHeader: parseImageHeader(imgData),
		layers: [{id: Date.now(), color: getDefaultColor(getState().art.present.sampler), isVisible: true, samplerId: getState().art.present.samplerId, paintMethod: PaintMethod.INTERPOLATE} as SimulationLayer],
		id: Date.now(),
		width: width,
		height: height
	};
	
	dispatch(simulationCreated(simulation));
	
	return simulation.id;
}

export const duplicateSimulation = () => (dispatch: Dispatch, getState: () => RootState) => {
	const state = getState().art.present;
	if (!state.activeSimulationId) return;
	
	const sourceSimulation = findById(state.activeSimulationId, state.simulations);
	
	let simulation:Simulation = {
		...sourceSimulation,
		id: Date.now(),
		layers: [...sourceSimulation.layers].map((l: SimulationLayer) => {return {...l, id: Date.now() - Math.floor(Math.random() * 50)}})
	};
	
	console.log('newSimulation', simulation.id);
	dispatch(simulationCreated(simulation));
	dispatch(simulationActivated(simulation.id));
}

export const removeSimulation = (id: number) => (dispatch: Dispatch, getState: () => RootState) => {
	console.log('removeSimulation', id);
	
	const activeSimulationId = getState().art.present.activeSimulationId;
	const simulations = getState().art.present.simulations;
	
	let newActiveSimulationId = activeSimulationId;
	
	if (simulations.length === 1) {
		newActiveSimulationId = null;
	}
	else if (activeSimulationId && activeSimulationId === id) {
		const activeIndex = findIndexById(activeSimulationId, simulations);
		const newIndex = activeIndex === 0 ? activeIndex + 1 : activeIndex - 1;
		
		newActiveSimulationId = simulations[newIndex].id;
	}
	
	dispatch(simulationActivated(newActiveSimulationId));
	
	dispatch(simulationRemoved(id));
}



export const addMaskToLayer = (canvasData: ImageData, bounds: Bounds) => async (dispatch: Dispatch, getState: () => RootState) => {
	console.log('addMaskToLayer')
	let state = getState().art.present;
	if (!state.activeSimulationId || !state.activeLayerId) return;
	
	let simulation = findById(state.activeSimulationId, state.simulations);

	const layers = simulation.layers;
	const activeLayerId = state.activeLayerId;
	const oldMask = findById(activeLayerId, layers).mask ?? null;
	
	let completeMask: Mask|null = null;
	for (let i in layers) {
		let layer = layers[i];
		if (layer.id === activeLayerId) {
			continue;
		}
		if (layer.mask) {
			completeMask = concatMasks(layer.mask, completeMask);
		}
	}
	
	const newMask = createMask(canvasData, bounds, oldMask, completeMask);
	
	dispatch(setLayerMask(newMask));
}

export const removeMaskFromLayer = (canvasData: ImageData, bounds: Bounds) => async (dispatch: Dispatch, getState: () => RootState) => {
	console.log('removeMaskFromLayer')
	let state = getState().art.present;
	if (!state.activeSimulationId || !state.activeLayerId) return;
	
	let simulation = findById(state.activeSimulationId, state.simulations);
	
	const layers = simulation.layers;
	const activeLayerId = state.activeLayerId;
	const oldMask = findById(activeLayerId, layers).mask ?? null;
	
	const newMask = gumMask(canvasData, bounds, oldMask);
	
	dispatch(setLayerMask(newMask));
}

export const setLayerDefaultColor = () => (dispatch: Dispatch, getState: () => RootState) => {
	const sampler: Sampler | undefined = getState().art.present.sampler;
	
	if (sampler) {
		dispatch(setLayerColor(getDefaultColor(getState().art.present.sampler)));
	}
}

export const {openColorSamplerList, closeColorSamplerList, setSampler, clearSampler, setTool, setMoveMode, setBorderMode, setLayerColor, setLayerName, setLayerPaintMethod, setLayerMask, addLayer, removeLayer, removeAllLayers, setLayerActive, setLayerHilited, simulationActivated, simulationCreated, simulationRemoved, projectLoaded} = artSlice.actions;

export default artSlice.reducer;