import Mask from 'definitions/Mask';
import Settings from 'definitions/Settings';
import SimulationLayer from 'definitions/SimulationLayer';
import Size from 'definitions/Size';
import VisualError from 'definitions/VisualError';

export default interface AppState {
	mode: AppMode,
	settings?: Settings,
	visualError?: VisualError,
	confirmRemoveVersionId: number | null,
	showWarningProjectIsSaved: boolean,
	isServerAvailable?: boolean,
	isSaved: boolean,
	isSaving: boolean,
	isLoading: boolean,
	progresses: Progress[],
	name?: string,
	id?: string,
	email?: string,
	notificationSent: boolean,
	hasSimulations: boolean,
	isSimulationListOpen: boolean,
	isPreparedProjectsListOpen: boolean,
	isHelpOpen: boolean,
	samplerId?: string,
	settingBrush: number,
	settingGum: number,
	settingWand: number,
	zoom: number,
	artSize?: Size
}

export const ZOOM_STEP = 25;
export const ZOOM_MIN = 25;
export const ZOOM_BASE = 100;
export const ZOOM_MAX = 500;

export function circleStep2Value(step: number): number {
	if (step <= 7) {
		return step + 3;
	}
	else {
		return 8 + (step - 7) * 2;
	}
}

export function circleValue2Step(value: number): number {
	if (value <= 10) {
		return value - 3;
	}
	else {
		return (value - 8) / 2 + 7;
	}
}

export interface SimulationSaveData {
	id: number,
	imgData?: string,
	completeMask?: Mask,
	layers: SimulationLayer[],
	width: number,
	height: number
}


export interface Progress {
	id: string,
	items: ProgressRequest[],
	total: number,
	sent: number,
	text: string
}

export interface ProgressRequest {
	id: string,
	total: number,
	sent: number,
}

export enum AppMode {
	default = 'default',
	prepared = 'prepared'
}

export enum SamplerGroup {
	interior = 'interior',
	exterior = 'exterior'
}
