import {AnyAction, createSlice, Dispatch, ThunkDispatch} from "@reduxjs/toolkit";
import axios from 'axios';
import AppState, {AppMode, circleStep2Value, Progress, SimulationSaveData, ZOOM_BASE, ZOOM_MAX, ZOOM_MIN, ZOOM_STEP} from 'definitions/AppState';
import {Settings_Sampler} from 'definitions/Settings';
import Simulation from 'definitions/Simulation';
import {clearSampler, projectLoaded, removeLayer, setLayerColor, setLayerMask, setSampler, simulationActivated, simulationCreated, simulationRemoved} from 'redux/artReducer';
import {removeItemById, getImageBlob, findById} from 'definitions/Utils';
import {RootState} from 'redux/store';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const initialState: AppState = {
	mode: AppMode.default,
	isSaved: false,
	isSaving: false,
	isLoading: false,
	progresses: [],
	notificationSent: false,
	confirmRemoveVersionId: null,
	showWarningProjectIsSaved: false,
	hasSimulations: false,
	isSimulationListOpen: false,
	isPreparedProjectsListOpen: false,
	isHelpOpen: false,
	settingBrush: 10,
	settingGum: 10,
	settingWand: 20,
	zoom: 100,
}

const applicationSlice = createSlice({
	name: 'Application',
	initialState,
	reducers: {
		settingsReady: (state:AppState, action) => {
			state.settings = action.payload;
		},
		
		setMode: (state: AppState, action) => {
			state.mode = action.payload;
		},
		
		setServerAvailable: (state:AppState, action) => {
			state.isServerAvailable = action.payload;
		},
		
		setProjectId: (state:AppState, action) => {
			state.id = action.payload;
		},
		
		setProjectName: (state:AppState, action) => {
			state.name = action.payload;
		},
		
		setProjectEmail: (state:AppState, action) => {
			state.email = action.payload;
			state.notificationSent = false;
		},
		
		setNotificationSent: (state:  AppState, action) => {
			state.notificationSent = action.payload;
		},
		
		setVisualError: (state:AppState, action) => {
			state.visualError = action.payload;
		},
		
		setIsHelpOpen: (state:AppState, action) => {
			state.isHelpOpen = action.payload;
		},
		
		setConfirmRemoveVersionId: (state:AppState, action) => {
			state.confirmRemoveVersionId = action.payload;
		},
		
		setShowWarningProjectIsSaved: (state: AppState, action) => {
			state.showWarningProjectIsSaved = action.payload;
		},
		
		setIsSaving: (state: AppState, action) => {
			state.isSaving = action.payload;
		},
		
		setIsLoading: (state: AppState, action) => {
			state.isLoading = action.payload;
		},
		
		setIsSaved: (state: AppState, action) => {
			state.isSaved = action.payload;
		},
		
		upsertProgress: (state: AppState, action) => {
			const progressesOld = state.progresses;
			let progressesNew = [...progressesOld];
			let newProgress: Progress;
			
			const {id, itemId, total, sent, text} = action.payload;
			const newItem = {id: itemId, total: total, sent: sent};
			
			const index = progressesNew.findIndex((p) => p.id === id);
			
			if (index >= 0) {
				newProgress = progressesNew[index];
				
				let itemIndex = newProgress.items.findIndex((pr) => pr.id === itemId);
				if (itemIndex >= 0) {
					newProgress.items[itemIndex] = newItem;
				}
				else {
					newProgress.items.push(newItem);
				}
				
				newProgress.total = newProgress.items.reduce((sum, pr) => sum + pr.total, 0);
				newProgress.sent = newProgress.items.reduce((sum, pr) => sum + (pr.total > 0 ? pr.sent : 0), 0); //gzip nema content length, takze to nemuzu pocitat a tim padem to bylo cely zbytecny.  no neva
			}
			else {
				newProgress = {id: id, total: total, sent: sent, text: text, items: [newItem]};
				progressesNew.push(newProgress);
			}

			state.progresses = progressesNew;
		},
		
		removeProgress: (state: AppState, action) => {
			const progressesOld = state.progresses;
			const progressesNew = removeItemById(action.payload, progressesOld);
			
			state.progresses = progressesNew;
		},
		
		resetProgress: (state: AppState) => {
			state.progresses = [];
		},
		
		setIsPreparedProjectsListOpen: (state: AppState, action) => {
			state.isPreparedProjectsListOpen = action.payload;
		},
		
		toggleSimulationList: (state:AppState) => {
			state.isSimulationListOpen = !state.isSimulationListOpen;
		},
		
		setSettingBrush: (state: AppState, action) => {
			state.settingBrush = circleStep2Value(action.payload);
		},
		
		setSettingGum: (state: AppState, action) => {
			state.settingGum = circleStep2Value(action.payload);
		},
		
		setSettingWand: (state: AppState, action) => {
			state.settingWand = action.payload;
		},
		
		setZoom: (state: AppState, action) => {
			state.zoom = Math.max(Math.min(action.payload, ZOOM_MAX), ZOOM_MIN);
		},
		increaseZoom: (state: AppState) => {
			let zoom = Math.floor((state.zoom + ZOOM_STEP) / ZOOM_STEP) * ZOOM_STEP;
			state.zoom = Math.min(zoom, ZOOM_MAX);
		},
		decreaseZoom: (state: AppState) => {
			let zoom = Math.ceil((state.zoom - ZOOM_STEP) / ZOOM_STEP) * ZOOM_STEP;
			state.zoom = Math.max(zoom, ZOOM_MIN);
		},
		setArtSize: (state: AppState, action) => {
			state.artSize = action.payload;
		}
	},
	
	extraReducers: {
		[simulationActivated.type]: (state, action) => {
			if (action.payload === null) {
				state.hasSimulations = false;
			}
			else {
				state.hasSimulations = true;
			}
			state.zoom = ZOOM_BASE;
		},
		[simulationCreated.type]: (state, action) => {
			state.isSaved = false;
		},
		
		[simulationRemoved.type]: (state, action) => {
			state.isSaved = false;
			state.confirmRemoveVersionId = null;
			//TODO - maybe use another action to manage confirmOverlay
		},
		
		[setLayerColor.type]: (state: AppState, action) => {
			state.isSaved = false;
		},
		
		[setLayerMask.type]: (state: AppState, action) => {
			state.isSaved = false;
		},
		
		[removeLayer.type]: (state: AppState, action) => {
			state.isSaved = false;
		}
	}
});

export const initApp = () => async (dispatch: ThunkDispatch<RootState, void, AnyAction>, getState: () => RootState) => {
	await dispatch(loadSettings());
	
	const settings = getState().application.settings;
	if (settings && settings.defaultSamplerId) {
		dispatch(loadSampler(settings.defaultSamplerId));
	}
	
	dispatch(isServerAvailable());
}

export const loadSettings = () => async (dispatch: Dispatch) => {
	try {
		const response = await axios.get("settings.json?" + Date.now().toString());
		dispatch(settingsReady(response.data));
	}
	catch (e) {
		dispatch(setVisualError("Load settings failed."));
	}
}

export const loadSampler = (samplerId: string) => async (dispatch: ThunkDispatch<RootState, void, AnyAction>, getState: () => RootState) => {
	//todo - kdyz selze defaultni sampler, umoznit zmenu na jinej
	const settings = getState().application.settings;
	const samplerItem = settings?.samplers.find((sam: Settings_Sampler) => sam.id === samplerId);
	if (!samplerItem) return;
	
	try {
		const response = await axios.get(samplerItem?.file);
		dispatch(setSampler({samplerId: samplerId, sampler: response.data}));
	}
	catch(e) {
		dispatch(setVisualError("Chyba aplikace. Načtení vzorníku " + samplerItem.title + " selhalo."));
	}
}

export const isServerAvailable = () => async (dispatch: ThunkDispatch<RootState, void, AnyAction>, getState: () => RootState) => {
	try {
		const response = await axios.get(SERVER_URL + '/api/version');
		console.log(response.data)
		dispatch(setServerAvailable(typeof response.data.version === 'string' || response.data.version instanceof String));
	}
	catch(e) {
		dispatch(setServerAvailable(false));
	}
}

export const saveProject = () => async (dispatch: ThunkDispatch<RootState, void, AnyAction>, getState: () => RootState) => {
	dispatch(setIsSaving(true));
	
	let id = getState().application.id;
	if (!id) {
		id = Date.now().toString();
		dispatch(setProjectId(id));
	}
	console.log('saveProject', id);
	
	const simulations = getState().art.present.simulations;
	const name = getState().application.name;
	const email = getState().application.email;
	const notificationSent = getState().application.notificationSent;
	const simulationIds = simulations.map(s => s.id);
	
	let simulationData, options;
	let promises = [];
	
	for (let i:number = 0; i < simulations.length; i++) {
		const simulation = simulations[i];
		
		simulationData = {...simulation} as SimulationSaveData;
		delete simulationData.completeMask;
		delete simulationData.imgData;

		dispatch(upsertProgress({id: simulation.id, itemId: simulation.id, total: 1, sent: 0, text: "Ukládání simulace " + (i + 1)}))
		
		let formData = new FormData();
		formData.append('id', id);
		if (name) formData.append('name', name);
		if (email) formData.append('email', email);
		formData.append('notificationSent', notificationSent ? 'true' : 'false');
		formData.append('simulationIds', JSON.stringify(simulationIds));
		
		formData.append('simulationId', simulation.id.toString());
		formData.append('image', getImageBlob(simulation.imgData), 'image');
		formData.append('imageHeader', simulation.imgHeader);
		formData.append('simulationData', JSON.stringify(simulationData));

		options = {
			headers: {
				'RequestMode': "no-cors"
			},
			onUploadProgress: (progressEvent: ProgressEvent) => {
				dispatch(upsertProgress({id: simulation.id, itemId: simulation.id, total: progressEvent.total, sent: progressEvent.loaded, text: "Ukládání simulace " + (i + 1)}))
			}
		};
		
		promises[i+1] = axios.post(SERVER_URL + '/api/save/' + id, formData, options);
	}
	
	try {
		await Promise.all(promises);
		
		dispatch(setIsSaved(true));
	}
	catch(e) {
		console.log(e);
		dispatch(setVisualError("Uložení se nezdařilo. Zopakujte akci."));
	}
	
	if (email && !notificationSent) {
		try {
			let formData = new FormData();
			formData.append('email', email);
			await axios.post(SERVER_URL + '/api/notify/' + id, formData);
			
			dispatch(setNotificationSent(true));
		} catch (e) {
			console.log(e);
			dispatch(setVisualError("Odeslání emailu se nezdařilo."));
		}
	}
	
	dispatch(resetProgress());
	dispatch(setIsSaving(false));
	dispatch(setMode(AppMode.default));
}

export const saveProjectAsNew = () => async (dispatch: ThunkDispatch<RootState, void, AnyAction>, getState: () => RootState) => {
	console.log('saveProjectAsNew');
	const id = Date.now().toString();
	dispatch(setProjectId(id));
	dispatch(setNotificationSent(false));
	
	dispatch(saveProject());
}

export const loadProject = (id: string, isPrepared: boolean = false) => async (dispatch: Dispatch) => {
	dispatch(setIsLoading(true));
	
	let projectData;
	
	const options = {
		onDownloadProgress: (progressEvent: ProgressEvent) => {
			dispatch(upsertProgress({id: 0, itemId: 0, total: progressEvent.total, sent: progressEvent.loaded, text: "Načítání projektu"}));
		}
	};
	
	try {
		const response = await axios.get(SERVER_URL + '/api/load/' + id, options);
		projectData = response.data;
	}
	catch(e) {
		console.log(e)
		dispatch(resetProgress());
		dispatch(setVisualError("Načtení projektu se nezdařilo."));
	}
	
	if (!projectData) return false;
	
	let simulations: Simulation[] = [], simRes, simData, simOptions;
	let images: string[] = [];
	let promises = [];
	
	try {
		for (let i:number = 0; i < projectData.simulations.length; i++) {
			const simulationId: number = projectData.simulations[i];
			
			dispatch(upsertProgress({id: simulationId, itemId: simulationId, total: 1, sent: 0, text: "Načítání simulace " + (i + 1)}));
			
			simOptions = {
				params: {simulationId: simulationId},
				onDownloadProgress: (progressEvent: ProgressEvent) => {
					dispatch(upsertProgress({id: simulationId, itemId: simulationId, total: progressEvent.total, sent: progressEvent.loaded, text: "Načítání simulace " + (i + 1)}));
				}
			};
			
			promises[i] = axios.get(SERVER_URL + '/api/load-simulation/' + id, simOptions)
				.then((response) => {
					simulations.push(response.data);
				});
			
			simOptions = {
				params: {simulationId: simulationId},
				onDownloadProgress: (progressEvent: ProgressEvent) => {
					dispatch(upsertProgress({id: simulationId, itemId: simulationId + 'image', total: progressEvent.total, sent: progressEvent.loaded}));
				}
			};
			
			promises[i+100] = axios.get(SERVER_URL + '/api/load-simulation-image/' + id, simOptions)
			.then((response) => {
				images[simulationId] = response.data.imgData;
			});
		}
	
		await Promise.all(promises);
	}
	catch(e) {
		dispatch(setIsLoading(false));
		dispatch(resetProgress());
		dispatch(setVisualError("Načtení projektu se nezdařilo."));
		return false;
	}
	
	for (let i:number = 0; i < simulations.length; i++) {
		const header = simulations[i].imgHeader || 'data:image/png;base64'; // failsafe for saved in old version or missing header
		simulations[i].imgData = header + ',' + images[simulations[i].id];
	}

	dispatch(setIsLoading(false));
	dispatch(setIsSaved(true));
	dispatch(resetProgress());
	dispatch(clearSampler());
	
	if (projectData.name) {
		dispatch(setProjectName(projectData.name));
	}
	
	if (!isPrepared) {
		dispatch(setProjectId(projectData.id));
		
		if (projectData.email) {
			dispatch(setProjectEmail(projectData.email));
		}
		if (projectData.notificationSent) {
			dispatch(setNotificationSent(projectData.notificationSent));
		}
		dispatch(setMode(AppMode.default));
	}
	else {
		dispatch(setMode(AppMode.prepared));
	}
	
	dispatch(projectLoaded(simulations));
	dispatch(simulationActivated(simulations[0].id));
}

export const shareToEmail = (email: string) => async (dispatch: ThunkDispatch<RootState, void, AnyAction>, getState: () => RootState) => {
	const simulations = getState().art.present.simulations;
	const activeSimulationId = getState().art.present.activeSimulationId;
	const name = getState().application.name;
	
	const canvas = document.getElementById('canvasMain') as HTMLCanvasElement;
	
	if (!activeSimulationId || !canvas) return;
	
	const simulation = findById(activeSimulationId, simulations);
	
	//dispatch(upsertProgress({id: simulation.id, itemId: simulation.id, total: 1, sent: 0, text: "Ukládání simulace " + (i + 1)}))
	
	let formData = new FormData();
	formData.append('email', email);
	if (name) formData.append('name', name);
	formData.append('image', getImageBlob(simulation.imgData), 'image');
	formData.append('canvas', getImageBlob(canvas.toDataURL()), 'image');
	
	try {
		await axios.post(SERVER_URL + '/api/share-email', formData);
	} catch (e) {
		console.log(e);
		dispatch(setVisualError("Odeslání emailu se nezdařilo."));
	}
}
	

export const { setMode, setServerAvailable, setProjectId, setProjectName, setProjectEmail, setNotificationSent, setIsSaving, setIsSaved, setIsLoading, upsertProgress, removeProgress, resetProgress, setIsPreparedProjectsListOpen, toggleSimulationList, settingsReady, setVisualError, setIsHelpOpen, setConfirmRemoveVersionId, setShowWarningProjectIsSaved, setSettingBrush, setSettingGum, setSettingWand, setZoom, increaseZoom, decreaseZoom, setArtSize} = applicationSlice.actions;

export default applicationSlice.reducer;

//TODO - settings a json soubory do nejakeho adresare kam ma pristup jenom web ...nebo ASPON jinam, nez kde jsou obrazky