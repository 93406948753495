import MouseTool from 'components/Art/MouseTool';
import Bounds from 'definitions/Bounds';
import Color from 'definitions/Color';
import Point from 'definitions/Point';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from 'redux/store';


interface Props {
	width: number,
	height: number,
	color: Color,
	getPoint: (e: MouseEvent) => Point,
	saveCanvas: (bounds: Bounds) => void,
	canvasDrawRef: React.RefObject<HTMLCanvasElement>
}

export default function Brush(props:Props) {
	console.log("Brush")
	
	const color = props.color;
	const canvasRef = props.canvasDrawRef;
	const settingBrush = useSelector((state: RootState) => state.application.settingBrush);
	const zoom = useSelector((state: RootState) => state.application.zoom);
	
	const paint = (prevPoint: Point, newPoint: Point) => {
		if (!canvasRef.current) return;
		
		const canvas: HTMLCanvasElement = canvasRef.current;
		const context = canvas.getContext('2d');
		if (context) {
			context.strokeStyle = `rgb(${color.r}, ${color.g}, ${color.b})`;
			context.globalCompositeOperation = "source-over";
			context.lineJoin = 'round';
			context.lineWidth = settingBrush;
			
			context.beginPath();
			context.moveTo(prevPoint.x, prevPoint.y);
			context.lineTo(newPoint.x, newPoint.y);
			context.closePath();
			
			context.stroke();
		}
	};
	
	useEffect(() => {
		const canvas = canvasRef.current;
		
		if (!canvas) return;
		const r = Math.round(settingBrush / 2 * zoom / 100);
		canvas.style.cursor = `url('data:image/svg+xml;utf8,<svg id="svg" xmlns="http://www.w3.org/2000/svg" version="1.1" width="${r * 2}" height="${r * 2}"><circle cx="${r}" cy="${r}" r="${r}" stroke-width="1" style="stroke: black; fill: none;"/></svg>') ${r} ${r}, pointer`;
	}, [settingBrush, canvasRef])
	
	
	return <MouseTool getPoint={props.getPoint} mouseEnd={props.saveCanvas} mouseMove={paint} canvasRef={canvasRef} />;
}