import {Button} from 'components/Button';
import {ReactElement} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {loadProject, setIsPreparedProjectsListOpen} from 'redux/applicationReducer';
import {RootState} from 'redux/store';


interface Props {
}

export default function PreparedProjectsList(props: Props): ReactElement {
	const dispatch = useDispatch();
	const settings = useSelector((state: RootState) => state.application.settings);
	
	const onClick = (projectId: string) => {
		dispatch(loadProject(projectId, true));
		dispatch(setIsPreparedProjectsListOpen(false));
	}
	
	const preparedProjects = settings?.preparedProjects || [];

	return (
		<>
			<h2>Připravené fotografie</h2>
			<div className="Grid">
			{preparedProjects.map((pp, i) => (
				<Button color="#FFFFFF"  key={i} className={`Button--id-${pp.id}`} onClick={() => onClick(pp.id)}>
					<img src={pp.thumbnail} />
					<div className="text" >
						<div className="title">{pp.title}</div>
					</div>
				</Button>
			))}
			</div>
		</>
	);
}