import {HTMLAttributes, ReactElement, useEffect, useState} from 'react';

interface OwnProps{
	onClose?: () => void,
	enableBgClick?: boolean,
	overlayRef?: React.RefObject<HTMLDivElement>
}

type Props = OwnProps & HTMLAttributes<HTMLElement>

export default function Overlay(props:Props): ReactElement {
	const {className, onClose, enableBgClick = true} = props;
	const [minimized, setMinimized] = useState(1);
	
	const onBgClick = () => {
		if (enableBgClick) {
			setMinimized(1);
		}
	};
	
	useEffect(() => {setMinimized(0)}, []);
	
	const onTransitionEnd = () => {
		if (onClose && minimized) {
			onClose();
		}
	}
	
	return (
		<div className={`Overlay ${className || ''} ${minimized ? 'Overlay--minimized' : ''}`} ref={props.overlayRef}>
			<div className="Overlay__BackGround" onClick={onBgClick} onTransitionEnd={onTransitionEnd}></div>
			<div className={`Overlay__Panel `}>
				{props.children}
			</div>
		</div>
	)
}