import {Button} from 'components/Button';
import {ReactElement} from 'react';


interface Props {
	side: 'left' | 'right',
	hidden?: boolean,
	onClick?: () => void
}

export default function ScrollButton(props:Props): ReactElement {
	const {side, hidden, onClick} = props;
	
	return (
		<div className={`ScrollButton ScrollButton--${side} ${hidden ? 'hidden' : ''}`}>
			<Button onClick={onClick}>
				{side === 'left' ? '<' : '>'}
			</Button>
		</div>
	)
}