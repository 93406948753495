import ColorSamplerList from 'components/Colors/ColorSamplerList';
import ColorSamplerSelect from 'components/Colors/ColorSamplerSelect';
import Overlay from 'components/Overlay/Overlay';
import Color from 'definitions/Color';
import React, {ReactElement, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {closeColorSamplerList, openColorSamplerList, setLayerColor} from 'redux/artReducer';
import {RootState, useAppDispatch} from 'redux/store';


interface Props{}

export default function ColorSampler(props: Props): ReactElement {
	const dispatch = useAppDispatch();
	const isColorSamplerListOpen = useSelector((state:RootState) => state.art.present.isColorSamplerListOpen);
	const sampler = useSelector((state: RootState) => state.art.present.sampler);
	const [isListTabActive, setIsListTabActive] = useState<boolean>(false);
	
	const onClose = () => {
		dispatch(closeColorSamplerList());
		setIsListTabActive(false);
	}
	
	const computeTextColor = (color: Color): string => {
		const sum = color.r + color.g + color.b;
		return sum > 450 ? 'black' : 'white';
	}
	
	const colorsUI = sampler ?
		sampler.colors.map((c, i) => {
			return <div className="ColorSampler__Color" key={i} title={c.name} style={{backgroundColor: `rgb(${c.r}, ${c.g}, ${c.b})`, color: computeTextColor(c)}} onClick={() => {dispatch(setLayerColor(c))}}><span>{c.name}</span></div>;
		}) : <div className="ColorSampler__Placeholder" onClick={() => {dispatch(openColorSamplerList())}}></div>

	return (
		<div className="ColorSamplerContainer">
			<ColorSamplerSelect />
			
			{isColorSamplerListOpen &&
			  <Overlay className={`ColorSamplerList ${isListTabActive ? 'ColorSamplerList--tabactive' : ''}`} onClose={onClose}>
				  <ColorSamplerList onSelect={onClose} setIsListTabActive={setIsListTabActive}/>
			  </Overlay>
			}
			
			<div className="ColorSampler">{colorsUI}</div>
		</div>
	)
}