import {ZOOM_BASE} from 'definitions/AppState';
import {findById} from 'definitions/Utils';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {RootState, useAppDispatch} from 'redux/store';
import {setZoom, increaseZoom, decreaseZoom} from 'redux/applicationReducer';
import ico_zoom_fit from "scss/assets/ico_zoom_fit.svg"
import ico_zoom_refresh from "scss/assets/ico_zoom_refresh.svg"

export function ZoomMenu () {
	const dispatch = useAppDispatch();
	const artSize = useSelector((state: RootState) => state.application.artSize);
	const simulations = useSelector((state: RootState) => state.art.present.simulations);
	const activeSimulationId = useSelector((state: RootState) => state.art.present.activeSimulationId);
	const zoomValue = useSelector((state: RootState) => state.application.zoom);
	const zoomString = zoomValue + '%';
	
	const fit = () => {
		if (!artSize || !activeSimulationId) return;
		
		const simulation = findById(activeSimulationId, simulations);

		const sizeDiff = Math.max(simulation.width / artSize.w, simulation.height / artSize.h);
		dispatch(setZoom(Math.round(1 / sizeDiff * 100)))
	}

	/*** initial zoom to "fit", only for images larger than the stage ***/
	useEffect(() => {
		if (!artSize || !activeSimulationId) return;

		const simulation = findById(activeSimulationId, simulations);

		if (simulation.width > artSize.w || simulation.height > artSize.h) {
			fit();
		}
	}, [artSize, activeSimulationId])
	
	return (
		<div className="AppPanel__ZoomMenu">
			<div className="AppPanel__ZoomMenu__Menu">
				<button className="AppPanel__ZoomMenu__Menu__Button" onClick={() => dispatch(decreaseZoom())}>-</button>
				<button className="AppPanel__ZoomMenu__Menu__Button" onClick={() => dispatch(increaseZoom())}>+</button>
				<span className="AppPanel__ZoomMenu__Menu__Value">{zoomString}</span>
				<button className="AppPanel__ZoomMenu__Menu__Button AppPanel__ZoomMenu__Menu__Button--refresh" onClick={() => dispatch(setZoom(ZOOM_BASE))} title='reset to original'><img src={ico_zoom_refresh} alt='reset to original' /></button>
				<button className="AppPanel__ZoomMenu__Menu__Button AppPanel__ZoomMenu__Menu__Button--fit" onClick={fit} title='fit to stage'><img src={ico_zoom_fit} alt='fit to stage' /></button>
			</div>
			<div className="AppPanel__ZoomMenu__Background">
				<div className="AppPanel__ZoomMenu__Background__Visible"></div>
			</div>
		</div>
	)
}
