import Bounds from 'definitions/Bounds';

export default interface Mask {
	data: number[], // Uint8Array
	width: number,
	height: number,
	bounds: Bounds
}

export function createMask(canvasData: ImageData, bounds: Bounds, oldMask: Mask|null, completeMask: Mask|null): Mask {
	const w = canvasData.width;
	const h = canvasData.height;
	const bDefault: Bounds = {minX: 10000, minY: 10000, maxX: 0, maxY:0};
	
	let newArray      = new Uint8Array(w * h);
	let bNew: Bounds  = {...(oldMask?.bounds ?? bDefault)};
	
	let	j, k, k1, k2, len;
	
	// nebo //let newArray = oldMask ? Uint8Array.from(oldMask.data) : new Uint8Array(w * h);
	
	/** copy points from oldMask */
	if (oldMask) {
		const oldData = Uint8Array.from(oldMask.data);
		const oldB = oldMask.bounds;
		
		len = oldB.maxX - oldB.minX + 1;
		k1 = oldB.minY * w + oldB.minX;
		k2 = oldB.maxY * w + oldB.minX + 1;
		
		// copy rows
		for (k = k1; k < k2; k += w) {
			newArray.set(oldData.subarray(k, k + len), k);
		}
	}
	
	/** copy new point, if not already used */
	const newData = canvasData.data;
	
	len = bounds.maxX - bounds.minX + 1;
	k1  = bounds.minY * w + bounds.minX;
	k2  = bounds.maxY * w + bounds.minX + 1;
	// walk through rows (Y)
	for (k = k1; k < k2; k += w) {
		// walk through cols (X)
		for (j = 0; j < len; j++) {
			if (newData[(k + j) * 4] > 0) { // is painted now
				if (completeMask && completeMask?.data[k + j] > 0) {// is already painted
					continue;
				}
				
				newArray[k + j] = 1;
				
				bNew.minX = Math.min(bNew.minX, bounds.minX + j);
				bNew.minY = Math.min(bNew.minY, k/w|0);
				bNew.maxX = Math.max(bNew.maxX, bounds.minX + j);
				bNew.maxY = Math.max(bNew.maxY, k/w|0);
			}
		}
	}
	
	return {
		data: Array.from(newArray),
		width: canvasData.width,
		height: canvasData.height,
		bounds: bNew
	};
}

export function gumMask(canvasData: ImageData, bounds: Bounds, oldMask: Mask|null): Mask {
	const w = canvasData.width;
	const h = canvasData.height;
	const bDefault: Bounds = {minX: 10000, minY: 10000, maxX: 0, maxY:0};
	
	let newArray      = new Uint8Array(w * h);
	let bNew: Bounds  = {...(oldMask?.bounds ?? bDefault)};
	
	let	j, k, k1, k2, len;
	
	// nebo //let newArray = oldMask ? Uint8Array.from(oldMask.data) : new Uint8Array(w * h);
	
	/** copy points from oldMask */
	if (oldMask) {
		const oldData = Uint8Array.from(oldMask.data);
		const oldB = oldMask.bounds;
		
		len = oldB.maxX - oldB.minX + 1;
		k1 = oldB.minY * w + oldB.minX;
		k2 = oldB.maxY * w + oldB.minX + 1;
		
		// copy rows
		for (k = k1; k < k2; k += w) {
			newArray.set(oldData.subarray(k, k + len), k);
		}
	}
	
	/** copy new point, if not already used */
	const newData = canvasData.data;
	
	len = bounds.maxX - bounds.minX + 1;
	k1  = bounds.minY * w + bounds.minX;
	k2  = bounds.maxY * w + bounds.minX + 1;
	// walk through rows (Y)
	for (k = k1; k < k2; k += w) {
		// walk through cols (X)
		for (j = 0; j < len; j++) {
			if (newData[(k + j) * 4] > 0) { // is painted now
				newArray[k + j] = 0;
				/*
				bNew.minX = Math.min(bNew.minX, bounds.minX + j);
				bNew.minY = Math.min(bNew.minY, k/w|0);
				bNew.maxX = Math.max(bNew.maxX, bounds.minX + j);
				bNew.maxY = Math.max(bNew.maxY, k/w|0);
				 */
				//TODO bounds se neprepocitaji
			}
		}
		
		// if bnew.miny === b.miny >> check this row if empty >> pokud je, tak posunout bnew o jeden dal
		// to same pro x
	}
	
	return {
		data: Array.from(newArray),
		width: canvasData.width,
		height: canvasData.height,
		bounds: bNew
	};
}

export function concatMasks (mask: Mask, old: Mask|null): Mask {
	if (!old) {
		return mask;
	}
	
	const data1 = Uint8Array.from(old.data);
	const data2 = Uint8Array.from(mask.data);
	const	w1 = old.width;
	const	w2 = mask.width;
	const	b1 = old.bounds;
	const	b2 = mask.bounds;
	const	b = { // bounds for new mask
		minX: Math.min(b1.minX, b2.minX),
		minY: Math.min(b1.minY, b2.minY),
		maxX: Math.max(b1.maxX, b2.maxX),
		maxY: Math.max(b1.maxY, b2.maxY)
	};
	
	const	w = old.width; // size for new mask
	const	h = old.height;
	
	let	i, j, k, k1, k2, len;
	
	let result = new Uint8Array(w * h);
	
	// copy all old mask
	len = b1.maxX - b1.minX + 1;
	i = b1.minY * w + b1.minX;
	k1 = b1.minY * w1 + b1.minX;
	k2 = b1.maxY * w1 + b1.minX + 1;
	// walk through rows (Y)
	for (k = k1; k < k2; k += w1) {
		result.set(data1.subarray(k, k + len), i); // copy row
		i += w;
	}
	
	// copy new mask (only "black" pixels)
	len = b2.maxX - b2.minX + 1;
	i = b2.minY * w + b2.minX;
	k1 = b2.minY * w2 + b2.minX;
	k2 = b2.maxY * w2 + b2.minX + 1;
	// walk through rows (Y)
	for (k = k1; k < k2; k += w2) {
		// walk through cols (X)
		for (j = 0; j < len; j++) {
			if (data2[k + j] === 1) {
				result[i + j] = 1;
			}
		}
		i += w;
	}
	
	return {
		data: Array.from(result),
		width: w,
		height: h,
		bounds: b
	};
}