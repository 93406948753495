import Wall from 'components/Walls/Wall';
import {findById} from 'definitions/Utils';
import React, {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {RootState, useAppDispatch} from 'redux/store';
import {addLayer, setLayerActive, setLayerHilited, removeAllLayers, setBorderMode} from 'redux/artReducer';
import ico_trash_all from "scss/assets/ico_trash_all.svg";
import ico_border from "scss/assets/ico_border.svg";

interface Props {
	isDisabled: boolean
}

export function Walls(props: Props): ReactElement | null {
	console.log('Walls');
	
	const borderMode = useSelector((state: RootState) => state.art.present.borderMode);
	const simulations = useSelector((state: RootState) => state.art.present.simulations);
	const activeLayerId = useSelector((state: RootState) => state.art.present.activeLayerId);
	const activeSimulationId = useSelector((state: RootState) => state.art.present.activeSimulationId);
	
	const dispatch = useAppDispatch();
	
	const onClickPlus = () => {
		dispatch(addLayer());
	}
	
	const onClickWall = (i:number) => {
		dispatch(setLayerActive(i));
	}
	
	const onClickTrashAll = () => {
		dispatch(removeAllLayers());
	}
	
	const onClickBorders = () =>  {
		dispatch(setBorderMode(!borderMode))
	}
	
	if  (!activeSimulationId || !activeLayerId) return null;
	
	const layers = findById(activeSimulationId, simulations).layers;
	
	return (
		<div className="Walls box">
			<div className="Walls__row">
				<button className={`ReliefButton ReliefButton--inline ReliefButton--border ${borderMode ? 'ReliefButton--active' : ''}`} onClick={onClickBorders} title="Zobrazit obrysy"><img src={ico_border} /></button>
				
				<button className="ReliefButton ReliefButton--inline ReliefButton--remove" onClick={onClickTrashAll} disabled={props.isDisabled} title="Smazat všechny vrstvy">
					<img src={ico_trash_all} />
				</button>
				<button className="ReliefButton ReliefButton--inline ReliefButton--add" onClick={onClickPlus} disabled={props.isDisabled} title="Přidat vrstvu">
					<span className="">+</span>
				</button>
			</div>
			{layers && layers.map((layer, i) => (
				<Wall key={i} isActive={activeLayerId === layer.id} colorItem={layer.color} layerId={layer.id} layerName={layer.name} isSingle={layers.length <= 1} paintMethod={layer.paintMethod}
				      isDisabled={props.isDisabled}
				      onClick={() => {onClickWall(layer.id)}}
				      onMouseEnter={() => {dispatch(setLayerHilited(layer.id))}}
				      onMouseLeave={() => {dispatch(setLayerHilited(activeLayerId))}}
				/>
			))}
			<hr />
		</div>
	)
}