import Tool from 'components/DrawingTools/Tool';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {ActionCreators} from 'redux-undo';
import {RootState, useAppDispatch} from 'redux/store';
import {setSettingBrush, setSettingGum, setSettingWand} from 'redux/applicationReducer';
import useKeyboardShortcut from 'use-keyboard-shortcut';

interface Props {
	isDisabled?: boolean
}

export function DrawingTools(props: Props): ReactElement {
	const dispatch = useAppDispatch();
	const settingBrush = useSelector((state: RootState) => state.application.settingBrush);
	const settingGum = useSelector((state: RootState) => state.application.settingGum);
	const settingWand = useSelector((state: RootState) => state.application.settingWand);
	const canUndo = useSelector((state: RootState) => state.art.past).length > 0;
	const canRedo = useSelector((state: RootState) => state.art.future).length > 0;
	const isDisabled = props.isDisabled;
	const undo = ()  => {
		dispatch(ActionCreators.undo());
	}
	
	const redo = ()  => {
		dispatch(ActionCreators.redo());
	}
	//TODO - use better shortcuts, allowing to hold ctrl
	useKeyboardShortcut(['Control', 'Z'], () => {
		console.log('Ctrl + Z has been pressed.');
		if (canUndo) {
			undo();
		}
	}, { overrideSystem: false })
	
	useKeyboardShortcut(['Control', 'Y'], () => {
		console.log('Ctrl + Y has been pressed.');
		if (canRedo) {
			redo();
		}
	}, { overrideSystem: false })
	
	return (
		<div className="DrawingTools">
			<div className="box">
				<div className="DrawingTools__row">
					<Tool id="back" className="ReliefButton--inline ReliefButton--left" onClick={canUndo ? undo : ()=>{}} disabled={!canUndo || isDisabled}>{true && '<'}</Tool>
					<Tool id="forwward" className="ReliefButton--inline ReliefButton--right" onClick={canRedo ? redo : ()=>{}} disabled={!canRedo || isDisabled}>{true && '>'}</Tool>
				</div>
				<Tool id="wand" icon={<img src="ico_wand.png" alt="" />} settingType="value" max={50} settingHandler={setSettingWand} currentValue={settingWand} disabled={isDisabled}>Barvit plochu</Tool>
				<Tool id="brush" icon={<img src="ico_brush.png" alt="" />} settingType="circle" max={20} settingHandler={setSettingBrush} currentValue={settingBrush} disabled={isDisabled}>Štětec</Tool>
				<Tool id="area" icon={<img src="ico_area.png" alt="" />} disabled={isDisabled}>Kreslit plochu</Tool>
				<Tool id="areacut" icon={<img src="ico_areacut.png" alt="" />} disabled={isDisabled}>Kreslit výřez</Tool>
				<Tool id="gum" icon={<img src="ico_gum.png" alt="" />} settingType="circle" max={20} settingHandler={setSettingGum} currentValue={settingGum} disabled={isDisabled}>Guma</Tool>
			</div>
		</div>
	)
}