import {Button} from 'components/Button';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {setProjectName, setProjectEmail} from 'redux/applicationReducer';
import {RootState, useAppDispatch} from 'redux/store';
import {AppMode} from 'definitions/AppState';

interface Props {
}

export function ProjectProperties (props: Props) {
	const id = useSelector((state: RootState) => state.application.id);
	const name = useSelector((state: RootState) => state.application.name);
	const email = useSelector((state: RootState) => state.application.email);
	const isServerAvailable = useSelector((state: RootState) => state.application.isServerAvailable);
	const appMode = useSelector((state: RootState) => state.application.mode);
	
	const [isEditName, setIsEditName] = useState(false);
	const [isEditEmail, setIsEditEmail] = useState(false);
	
	const dispatch = useAppDispatch();
	
	const setName = () => {
		const input = document.getElementById('name') as HTMLInputElement;
		const val = input.value;
		if (val) {
			dispatch(setProjectName(val));
		}
		setIsEditName(false);
	}
	
	const setEmail = () => {
		const input = document.getElementById('email') as HTMLInputElement;
		const val = input.value;
		if (val) {
			dispatch(setProjectEmail(val));
		}
		setIsEditEmail(false);
	}
	
	useEffect(() => {
		const input = document.getElementById('name') as HTMLInputElement;
		if (input) {
			input.focus();
		}
		else {
			const inputEmail = document.getElementById('email') as HTMLInputElement;
			if (inputEmail) {
				inputEmail.focus();
			}
		}
	})
	
	const stateDisplay = isServerAvailable ? null : (isServerAvailable === undefined ? <span className='error'>ověřuji spojení se serverem...</span> : <span className='error'>OFFLINE</span>);
	
	let idDisplay = id ? 'ID: ' + id : 'Není uloženo...';
	if (appMode === AppMode.prepared) {
		idDisplay = 'UKÁZKOVÝ PROJEKT';
	}
	
	let titleDisplay;
	if (appMode === AppMode.prepared) {
		titleDisplay = name || 'Bez názvu...';
	}
	else if (isEditName) {
		titleDisplay = <span><input id="name" type="text" defaultValue={name} onBlur={setName} /><Button className="Button--small" onClickCapture={setName}>OK</Button></span>;
	}
	else {
		titleDisplay = <a onClick={() => setIsEditName(true)}>{name || 'Bez názvu...'} <svg width="15" height="15"><use xlinkHref="#ico_edit" /></svg></a>;
	}
	
	let emailDisplay;
	if (isEditEmail) {
		emailDisplay = <span><input id="email" type="email" defaultValue={email} onBlur={setEmail} /><Button className="Button--small" onClickCapture={setEmail}>OK</Button></span>;
	}
	else if (email) {
		emailDisplay = <a onClick={() => setIsEditEmail(true)}>Email: {email} <svg width="15" height="15"><use xlinkHref="#ico_edit" /></svg></a>;
	}
	else {
		emailDisplay = '';
	}
	
	return (
		<div className={`AppPanel__ProjectProperties AppPanel__ProjectProperties${!id && '--notSaved'}`}>
			<div className="AppPanel__ProjectProperties__title">{titleDisplay}</div>
			<div>
				<div className="AppPanel__ProjectProperties__id">{idDisplay} {stateDisplay}</div>
				<div className="AppPanel__ProjectProperties__email">{emailDisplay}</div>
			</div>
		</div>
	);
}
