import {MoveMenu} from 'components/AppPanel/MoveMenu';
import {ProjectMenu} from 'components/AppPanel/ProjectMenu';
import {ProjectProperties} from 'components/AppPanel/ProjectProperties';
import {SimulationsButton} from 'components/AppPanel/SimulationsButton';
import {ZoomMenu} from 'components/AppPanel/ZoomMenu';
import {Button} from 'components/Button';
import ColorSampler from 'components/Colors/ColorSampler';
import Homepage from 'components/Homepage/Homepage';
import MainContainer from 'components/MainContainer';
import MessageOverlay from 'components/Overlay/MessageOverlay';
import Overlay from 'components/Overlay/Overlay';
import PreparedProjectsList from 'components/PreparedProjectsList/PreparedProjectsList';
import ProgressBar from 'components/ProgressBar';
import LeftContainer from 'components/LeftContainer';
import YoutubeTutorial from 'components/YoutubeTutorial';
import React from 'react';
import './scss/common.scss'
import logo from 'scss/assets/HET_logo_RGB.png';
import {useSelector} from 'react-redux';
import {RootState, useAppDispatch} from 'redux/store';
import {setVisualError, setConfirmRemoveVersionId, setIsPreparedProjectsListOpen, setIsHelpOpen} from 'redux/applicationReducer';
import {removeSimulation} from 'redux/artReducer';

function App() {
	const hasSimulations = useSelector((state: RootState) => state.application.hasSimulations);
	const settings = useSelector((state: RootState) => state.application.settings);
	const samplerId = useSelector((state: RootState) => state.art.present.samplerId);
	const visualError = useSelector((state: RootState) => state.application.visualError);
	const isHelpOpen = useSelector((state: RootState) => state.application.isHelpOpen);
	const confirmRemoveVersionId = useSelector((state: RootState) => state.application.confirmRemoveVersionId);
	const isPreparedProjectsListOpen = useSelector((state: RootState) => state.application.isPreparedProjectsListOpen);
	const isLoading = useSelector((state: RootState) => state.application.isLoading);
	const isSaving = useSelector((state: RootState) => state.application.isSaving);
	const progresses = useSelector((state: RootState) => state.application.progresses);
	const dispatch = useAppDispatch();
	
	const onConfirmRemoveVersion = (i: number|null) => {
		dispatch(setConfirmRemoveVersionId(i));
	}
	//TODO dodelat email-edit do panelu
  return (
    <div className="App" id="App">
     
	    <div className="AppPanel">
		    <div className="AppPanel__Brand">
					<a href="https://www.het.cz" target="_blank" rel="noreferrer"><img src={logo} className="AppPanel__Brand__Logo" alt="logo" /></a>
          <span className="AppPanel__Brand__AppTitle">ColorSimulator 3.0</span>
		    </div>
		    
	      <SimulationsButton />
	      <ProjectProperties />
	      
		    {hasSimulations && <MoveMenu />}
		    {hasSimulations && <ZoomMenu />}
	      
	      <ProjectMenu />
      </div>
			<div className={`Content ${hasSimulations ? '' : 'Content--home'}`}>
				{hasSimulations && settings && <LeftContainer samplerId={samplerId} />}
				{hasSimulations && settings && <ColorSampler />}
				{hasSimulations ? <MainContainer /> : <Homepage />}
	    </div>
	    
	    {isPreparedProjectsListOpen && <Overlay className="PreparedProjectsList" enableBgClick={true} onClose={() => dispatch(setIsPreparedProjectsListOpen(false))}><PreparedProjectsList /></Overlay>}
	    
	    {(confirmRemoveVersionId !== null) && <MessageOverlay className="MessageOverlay--confirm" enableBgClick={true} onClose={() => onConfirmRemoveVersion(null)} >
			    <div>Chcete smazat tuto verzi?</div>
			    <Button className="Button--ano Button--success" onClick={() => {dispatch(removeSimulation(confirmRemoveVersionId))}}>Ano</Button>
			    <Button className="Button--ne" onClick={() => onConfirmRemoveVersion(null)}>Ne</Button>
	      </MessageOverlay>
	    }
	    
	    {(isLoading || isSaving) && progresses.length && <ProgressBar progresses={progresses} />}
	
	    {isHelpOpen && <MessageOverlay className="MessageOverlay--help" onClose={() => dispatch(setIsHelpOpen(false))} enableBgClick={true}><YoutubeTutorial/></MessageOverlay>}
	
	    {visualError && <MessageOverlay className="MessageOverlay--error" onClose={() => dispatch(setVisualError(null))} enableBgClick={false}><div>{visualError}</div></MessageOverlay>}
	
	    <div className="hidden">
		    <svg width="15" height="15" viewBox="0 0 128 128" preserveAspectRatio="xMidYMid meet" id="ico_edit">
			
			    <g transform="translate(0,128) scale(0.100000,-0.100000)" stroke="none">
				    <path d="M1015 1270 c-11 -4 -50 -39 -87 -77 l-68 -68 133 -133 133 -133 72 75 c100 102 99 111 -15 228 -73 75 -130 121 -145 117 -2 0 -12 -4 -23 -9z"/>
				    <path d="M392 657 l-392 -393 0 -127 0 -127 138 0 137 0 388 388 387 387 -133 133 -132 132 -393 -393z"/>
			    </g>
		    </svg>
		
		    <svg width="15" height="15" viewBox="0 0 20 20" preserveAspectRatio="xMidYMid meet" id="ico_trash">
			    <g>
				    <path id="svg_3" d="m6.94714,2.13799c-0.4,0.8 -1.6,1.5 -2.6,1.7c-2.2,0.45 -2.475,2.2375 -1.15,2.325c1.325,0.0875 3.6625,0.15 6.7625,0.125c3.1,-0.025 4.9,-0.0125 6.16875,0.0375c1.26875,0.05 0.86875,-1.775 -0.44375,-2.275c-1.3125,-0.5 -2.0375,-1.0125 -2.4375,-1.8125c-0.7,-1.9 -5.2,-2 -6.3,-0.1zm4.4625,1.6375c-0.3,0.3 -1.88749,0.3375 -2.58749,0.0375c-0.8,-0.3 0.125,-0.975 1.225,-0.975c1.1,-0.1 1.7625,0.6375 1.3625,0.9375z"/>
				    <path id="svg_4" d="m3.84714,13.43799l0.3,5.8l6,0l6,0l0.3,-5.8l0.3,-5.7l-6.6,0l-6.6,0l0.3,5.7zm3.8,0.3c-0.0625,3.45 -0.4,4 -1,4c-0.5,0 -1,-0.6125 -1,-4c0,-3.3875 0.5,-4 1,-4c0.6,0 1.0625,0.55 1,4zm2.5125,3.95c-0.825,0.1125 -0.8375,-1.7625 -0.8375,-4.2625c0,-2.5 0.2,-3.625 0.9,-3.55c0.7,0.075 0.91563,0.85 0.86875,3.725c-0.04688,2.875 -0.10625,3.975 -0.93125,4.0875zm4.425,-4.0125c0,2.8875 -0.3375,4.0625 -0.9375,4.0625c-0.5,0 -1,-0.925 -1,-4c0,-3.075 0.5,-4 1,-4c0.6,0 0.9375,1.05 0.9375,3.9375z"/>
			    </g>
		    </svg>
	    </div>
    </div>
  );
}

export default App;
