import {MenuButton} from 'components/AppPanel/MenuButton';
import {HTMLAttributes, ReactElement, ReactNode, useState} from 'react';

interface OwnProps {
	disabled?: boolean,
	buttonLabel: string,
	buttonIcon?: ReactNode,
	closeOnClick?: boolean
}

type Props = OwnProps & HTMLAttributes<HTMLElement>

export function ProjectMenuItem(props: Props): ReactElement {
	const [isOpen, setIsOpen] = useState(false);
	
	const onSubmenuClick = () => {
		if (props.closeOnClick) {
			setIsOpen(false);
		}
	}
	
	return (
		<div className="AppPanel__ProjectMenu__Item AppPanel__ProjectMenu__Item--open" onMouseLeave={() => {setIsOpen(false)}}>
			<MenuButton className="" leftIcon={props.buttonIcon} color={'rgba(255,255,255,0.8)'} onClick={() => {setIsOpen(!isOpen)}} disabled={props.disabled}>{props.buttonLabel}</MenuButton>
			{isOpen && <div className="AppPanel__ProjectMenu__Item__Submenu" onClick={onSubmenuClick}>
				{props.children}
		  </div>
			}
		</div>
	)
}