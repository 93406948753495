import Bounds from 'definitions/Bounds';
import Color from 'definitions/Color';
import Point from 'definitions/Point';
import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from 'redux/store';


interface Props {
	width: number,
	height: number,
	color: Color,
	getPoint: (e: MouseEvent) => Point,
	saveCanvas: (bounds: Bounds) => void,
	canvasDrawRef: React.RefObject<HTMLCanvasElement>
}

export default function Polygon(props:Props) {
	const color = props.color;
	const colorString = `rgb(${color.r}, ${color.g}, ${color.b})`;
	const canvasRef = props.canvasDrawRef;
	const boundsRef = useRef<Bounds>({minX: 10000, minY: 10000, maxX: 0, maxY:0});
	const path = useRef<Point[]>([]);
	const startButtonRef = useRef<HTMLDivElement>(null);
	const [isDraw, setIsDraw] = useState(false);
	const [startPoint, setStartPoint] = useState<Point>({x: 0, y: 0});
	const zoom = useSelector((state: RootState) => state.application.zoom);
	
	
	const getNewBounds = (p: Point): Bounds => {
		let newBounds = {...boundsRef.current};
		newBounds.minX = Math.min(newBounds.minX, p.x);
		newBounds.minY = Math.min(newBounds.minY, p.y);
		newBounds.maxX = Math.max(newBounds.maxX, p.x);
		newBounds.maxY = Math.max(newBounds.maxY, p.y);
		
		return newBounds;
	}
	
	const draw = (event:MouseEvent) => {
		const context = canvasRef.current?.getContext('2d');
		if (!context) return;
		
		const point = props.getPoint(event);
		const lastPoint = path.current.slice(-1).pop();
		
		if (!lastPoint) return;
		
		drawPolygon();
		
		context.beginPath();
		context.moveTo(lastPoint.x, lastPoint.y);
		context.lineTo(point.x, point.y);
		context.closePath();
		context.stroke();
	}
	
	const startSegment = (event:MouseEvent) => {
		const context = canvasRef.current?.getContext('2d');
		if (!context) return;

		const point = props.getPoint(event);
		boundsRef.current = getNewBounds(point);
		path.current.push(point);
		
		if (!isDraw) {
			setStartPoint(point);
			setIsDraw(true);
			
			return;
		}
		
		drawPolygon();
	}
	
	const finishDraw = () => {
		const context = canvasRef.current?.getContext('2d');
		if (!context) return;
		
		drawPolygon();
		context.closePath();
		context.fill();
		
		setIsDraw(false);
		path.current = [];
		
		props.saveCanvas(boundsRef.current);
	}
	
	const drawPolygon = () => {
		const context = canvasRef.current?.getContext('2d');
		if (!context) return;
		
		context.clearRect(0, 0, context.canvas.width, context.canvas.height);
		context.beginPath();
		context.moveTo(path.current[0].x, path.current[0].y);
		for (let i:number = 1; i < path.current.length; i++)  {
			context.lineTo(path.current[i].x, path.current[i].y)
		}
		context.stroke();
	}

	useEffect(() => {
		if (!canvasRef.current)	return;
		
		const canvas: HTMLCanvasElement = canvasRef.current;
		const context = canvas.getContext('2d');
		if (!context) return;
		
		context.globalCompositeOperation = "source-over";
		context.fillStyle = colorString;
		context.lineJoin = 'round';
		context.lineWidth = 1;
		
		canvas.addEventListener('mousedown', startSegment);
		
		if (isDraw) {
			canvas.addEventListener('mousemove', draw);
			canvas.addEventListener('dblclick', finishDraw);
		}
		return () => {
			canvas.removeEventListener('mousedown', startSegment);
			canvas.removeEventListener('mousemove', draw);
			canvas.removeEventListener('dblclick', finishDraw);
			context.clearRect(0, 0, context.canvas.width, context.canvas.height);
		};
	}, [isDraw]);
	
	useEffect(() => {
		const canvas = canvasRef.current;
		
		if (!canvas) return;
		canvas.style.cursor = `auto`;
	}, []);
	
	const pSize = Math.round(15 * 100 / zoom);
	const borderWidth = Math.round(2 * 100 / zoom);
	const startButtonPos = {x: startPoint.x - pSize/2, y: startPoint.y - pSize/2};
	
	return isDraw ? <div ref={startButtonRef} style={{width: pSize, height: pSize, left: startButtonPos.x, top: startButtonPos.y, borderWidth: borderWidth, backgroundColor: colorString}} className="Polygon__StartButton" onClick={finishDraw} /> : null;
}