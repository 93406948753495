import Brush from 'components/Art/Brush';
import Gum from 'components/Art/Gum';
import MoveTool from 'components/Art/MoveTool';
import Polygon from 'components/Art/Polygon';
import Wand from 'components/Art/Wand';
import Bounds from 'definitions/Bounds';
import {hslToRgb, rgbToHsl} from 'definitions/Color';
import {PaintMethod} from 'definitions/PaintMethod';
import Point from 'definitions/Point';
import Size, {Position} from 'definitions/Size';
import {findById} from 'definitions/Utils';
import {ReactElement, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {addMaskToLayer, removeMaskFromLayer} from 'redux/artReducer';
import {setArtSize} from 'redux/applicationReducer';
import {RootState, useAppDispatch} from 'redux/store';

const MagicWand = require('magic-wand-tool');

interface Props {
	activeSimulationId: number,
	activeLayerId: number
}

interface Border {
	id: number,
	border: number[]
}

export default function Art(props:Props): ReactElement | null {
	console.log("Art")
	
	const dispatch = useAppDispatch();
	const [size, setSize] = useState<Size | undefined>(undefined);
	const [position, setPosition] = useState<Position>({dX: '-50%', dY: '-50%', dLeft: '50%', dTop: '50%'});
	const [borders, setBorders] = useState<Border[]>([]);
	
	const rootRef = useRef<HTMLDivElement>(null);
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const canvasDrawRef = useRef<HTMLCanvasElement>(null);
	const canvasDataInitialRef = useRef<ImageData|null>(null);
	
	const tool =           useSelector((state: RootState) => state.art.present.tool);
	const moveMode =       useSelector((state: RootState) => state.art.present.moveMode);
	const borderMode =     useSelector((state: RootState) => state.art.present.borderMode);
	const zoom =           useSelector((state: RootState) => state.application.zoom);
	const simulations =    useSelector((state: RootState) => state.art.present.simulations);
	const hilitedLayerId = useSelector((state: RootState) => state.art.present.hilitedLayerId);
	const artSize =        useSelector((state: RootState) => state.application.artSize);
	
	const activeLayerId =      props.activeLayerId;
	const activeSimulationId = props.activeSimulationId;
	const simulation = findById(activeSimulationId, simulations);
	const layers = simulation.layers;
	const imgData = simulation.imgData;
	const layerActive = findById(activeLayerId, layers);
	
	const scale = (zoom / 100).toFixed(2);
	
	const getPoint = (event:MouseEvent): Point => ({x: event.offsetX, y: event.offsetY});
	
	const calcPosition = () => {
		if (!size || !rootRef.current) return;
		
		/** only smaller images then visible area can be centered */
		const overX = Math.round(size.w * zoom / 100) >= rootRef.current.clientWidth;
		const overY = Math.round(size.h * zoom / 100) >= rootRef.current.clientHeight;
		
		setPosition({
			dX: overX ? '0px' : '-50%',
			dY: overY ? '0px' : '-50%',
			dLeft: overX ? (zoom - 100) / 200 * size.w + 'px' : '50%',
			dTop: overY ? (zoom - 100) / 200 * size.h + 'px' : '50%'
		});
	}

	const addToLayer = (bounds: Bounds) => {
		console.log("SAVE Canvas")
		
		if (!canvasDrawRef.current) return;
		
		const canvasDraw: HTMLCanvasElement = canvasDrawRef.current;
		const contextDraw = canvasDraw.getContext('2d');
		if (contextDraw) {
			dispatch(addMaskToLayer(contextDraw.getImageData(0, 0, canvasDraw.width, canvasDraw.height), bounds));
		}
	};
	
	const removeFromLayer = (bounds: Bounds) => {
		console.log("SAVE Canvas")
		
		if (!canvasDrawRef.current) return;
		
		const canvasDraw: HTMLCanvasElement = canvasDrawRef.current;
		const contextDraw = canvasDraw.getContext('2d');
		if (contextDraw) {
			dispatch(removeMaskFromLayer(contextDraw.getImageData(0, 0, canvasDraw.width, canvasDraw.height), bounds));
		}
	};
	
	const hiliteBorder = () => {
		console.log('hiliteBorder');
		
		const context = canvasDrawRef.current?.getContext('2d');
		if (!context || !size || !hilitedLayerId) return;
		
		context.clearRect(0, 0, context.canvas.width, context.canvas.height);
		
		if (!borderMode) return;
			
		let newBorders = [...borders];
		let border = newBorders.find(b => b.id === hilitedLayerId)
		if (!border) {
			console.log("FIND INDICES for", hilitedLayerId)
			
			const layer = findById(hilitedLayerId, layers);
			if (!layer.mask) return;
			
			border = {id: hilitedLayerId, border: MagicWand.getBorderIndices(layer.mask)};
			newBorders.push(border);
			setBorders(newBorders);
		}
		
		let x, y, k, i, j,
				hatchLength = 4,
				hatchOffset = 0,
				imgData = context.createImageData(size.w, size.h),
				res = imgData.data;
		
		for (j = 0; j < border.border.length; j++) {
			i = border.border[j];
			x = i % size.w; // calc x by index
			y = (i - x) / size.w; // calc y by index
			k = i * 4;
			if ((x + y + hatchOffset) % (hatchLength * 2) < hatchLength) { // detect hatch color
				res[k + 3] = 255; // black, change only alpha
			} else {
				res[k] = res[k + 1] = res[k + 2] = res[k + 3] = 255;
			}
		}
		
		context.putImageData(imgData, 0, 0);
	}

	useEffect(() => {
		console.log("Art - init")
		if (!imgData) return;

		const image = new Image();
		image.onload = () => {
			const width = image.width;
			const height = image.height;

			setSize({w: width, h: height});

			const context = canvasRef.current?.getContext('2d');
			if (!context) return;

			context.drawImage(image, 0, 0, width, height);
			canvasDataInitialRef.current = context.getImageData(0, 0, width, height);
		}

		image.src = imgData;
	}, [imgData])


	useEffect(() => {
		console.log("Art - init artSize")
		if (!rootRef.current) return;
		
		const dispatchArtSize = () => dispatch(setArtSize({w: rootRef.current?.clientWidth, h: rootRef.current?.clientHeight}));

		dispatchArtSize();
		
		window.addEventListener('resize', dispatchArtSize);

		return () => window.removeEventListener('resize', dispatchArtSize);
	}, [rootRef])

	useEffect(() => {
		console.log("Art - change zoom")
		
		calcPosition();
		
	}, [size, zoom, artSize])

	useEffect(() => {
		console.log("Art - change", size);

		const initImageData = canvasDataInitialRef.current;
		const contextDraw = canvasDrawRef.current?.getContext('2d');
		const context = canvasRef.current?.getContext('2d');

		if (!contextDraw || !context || !size || !initImageData) return;

		/*** clear drawing canvas ***/

		contextDraw.clearRect(0, 0, size.w, size.h);

		/*** redraw all layers on base canvas ***/

		let dataArray: Uint8ClampedArray = new Uint8ClampedArray(size.w * size.h * 4);
		let i, j, avgSum, avgCnt, avgL = 0;
		let colorRGB, colorHSL, pointColorHSL, newColor;
		let len, k, l, k1, k2, p;

		for (j in layers) {
			let layer = layers[j];
			if (!layer.mask || !layer.color) continue;

			/** avg lightness, in bounds **/

			avgSum = avgCnt = 0;
			len = layer.mask.bounds.maxX - layer.mask.bounds.minX + 1;
			k1 = layer.mask.bounds.minY * size.w + layer.mask.bounds.minX;
			k2 = layer.mask.bounds.maxY * size.w + layer.mask.bounds.minX + 1;

			if (layer.paintMethod === PaintMethod.INTERPOLATE) {
				for (k = k1; k < k2; k += size.w) { // rows
					for (l = 0; l < len; l++) { // cols
						p = k + l;
						if (layer.mask.data[p] > 0) {
							pointColorHSL = rgbToHsl(initImageData.data[p * 4], initImageData.data[p * 4 + 1], initImageData.data[p * 4 + 2]);
							avgSum += pointColorHSL.l;
							avgCnt++;
						}
					}
				}
				avgL = avgSum / avgCnt;
			}
			colorHSL = rgbToHsl(layer.color.r, layer.color.g, layer.color.b);

			/** repaint masked points **/

			for (k = k1; k < k2; k += size.w) { // rows
				for (l = 0; l < len; l++) { // cols
					p = k + l;
					if (layer.mask.data[p] > 0) {
						if (layer.paintMethod === PaintMethod.INTERPOLATE) {
							pointColorHSL = rgbToHsl(initImageData.data[p * 4], initImageData.data[p * 4 + 1], initImageData.data[p * 4 + 2]);
							newColor = hslToRgb(colorHSL.h, colorHSL.s, pointColorHSL.l - avgL + colorHSL.l);
						}
						else {
							newColor = layer.color;
						}
						dataArray[p * 4] = newColor.r;
						dataArray[p * 4 + 1] = newColor.g;
						dataArray[p * 4 + 2] = newColor.b;
						dataArray[p * 4 + 3] = 255;
					}
				}
			}
		}

		context.putImageData(new ImageData(dataArray, size.w, size.h), 0, 0);
		
		setBorders([]);
		
	}, [activeLayerId, layers, size]);
	
	useEffect(() => {
		hiliteBorder();
	}, [borders, borderMode, hilitedLayerId])
	
	return !imgData ? null : (
		<div className={`Art ${tool}`} ref={rootRef}>
			<div className="Art__Container" style={{width: size?.w, height: size?.h, transform: `translate(${position.dX}, ${position.dY}) scale(${scale})`, left: position.dLeft, top: position.dTop}}>
				<img src={imgData} alt='' id='baseImage' />
				<canvas ref={canvasRef} width={size?.w} height={size?.h} id="canvasMain" />
				<canvas ref={canvasDrawRef} width={size?.w} height={size?.h} id="canvasDraw" />
				
				{size && layers && (
					(moveMode && <MoveTool artRef={rootRef} canvasRef={canvasDrawRef} getPoint={getPoint} />) ||
					(layerActive.color && tool === 'brush'   && <Brush   width={size.w} height={size.h} color={layerActive.color} getPoint={getPoint} canvasDrawRef={canvasDrawRef} saveCanvas={addToLayer} />) ||
					(layerActive.color && tool === 'gum'     && <Gum     width={size.w} height={size.h} color={layerActive.color} getPoint={getPoint} canvasDrawRef={canvasDrawRef} saveCanvas={removeFromLayer} />) ||
					(layerActive.color && tool === 'area'    && <Polygon width={size.w} height={size.h} color={layerActive.color} getPoint={getPoint} canvasDrawRef={canvasDrawRef} saveCanvas={addToLayer} key={activeLayerId} /> )||
					(layerActive.color && tool === 'areacut' && <Polygon width={size.w} height={size.h} color={layerActive.color} getPoint={getPoint} canvasDrawRef={canvasDrawRef} saveCanvas={removeFromLayer} key={activeLayerId} />) ||
					(layerActive.color && tool === 'wand'    && <Wand    width={size.w} height={size.h} color={layerActive.color} getPoint={getPoint} canvasDrawRef={canvasDrawRef} saveCanvas={addToLayer} canvasDataInitialRef={canvasDataInitialRef} mask={layerActive.mask ?? null} />)
				)}
			</div>
		</div>
	)
}