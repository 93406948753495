import {Button} from 'components/Button';
import {SamplerGroup} from 'definitions/AppState';
import {Settings_Sampler} from 'definitions/Settings';
import {Dispatch, ReactElement, SetStateAction, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {loadSampler} from 'redux/applicationReducer';
import {RootState} from 'redux/store';
import ico_exterior from 'scss/assets/fasadni_barvy.svg';
import ico_interior from 'scss/assets/interierove_barvy.svg';

interface Props {
	onSelect: () => void,
	setIsListTabActive: Dispatch<SetStateAction<boolean>>
}

export default function ColorSamplerList(props: Props): ReactElement {
	const dispatch = useDispatch();
	const settings = useSelector((state: RootState) => state.application.settings);
	const activeSamplerId = useSelector((state: RootState) => state.art.present.samplerId);
	const [isActive, setIsActive] = useState<SamplerGroup>();
	
	const onClick = (samplerId: string) => {
		dispatch(loadSampler(samplerId));
		props.onSelect();
	}
	
	const onTabClick = (tab: SamplerGroup) => {
		setIsActive(tab);
		props.setIsListTabActive(true);
	}
	
	const samplers = settings?.samplers || [];
	const activeGroup = !isActive ? null : (isActive === SamplerGroup.interior ? settings?.interiorSamplers : settings?.exteriorSamplers);
	
	return (
		<>
			<h2>VZORNÍKY</h2>
			<p>Kliknutím na vzorník změníte barevnou škálu.</p>
			<div className="TabsContainer">
				<div className="TabsContainer__Tabs">
					<button className={`TabsContainer__Tabs__Tab TabsContainer__Tabs__Tab--interior ReliefButton ${isActive === SamplerGroup.interior ? 'ReliefButton--active' : ''}`} onClick={() => onTabClick(SamplerGroup.interior)} >
						<span className="ReliefButton__icon"><img src={ico_interior} /></span>
						<span>INTERIÉROVÉ BARVY</span>
					</button>
					<button className={`TabsContainer__Tabs__Tab TabsContainer__Tabs__Tab--exterior ReliefButton ${isActive === SamplerGroup.exterior ? 'ReliefButton--active' : ''}`} onClick={() => onTabClick(SamplerGroup.exterior)} >
						<span className="ReliefButton__icon"><img src={ico_exterior} /></span>
						<span>FASÁDNÍ BARVY A OMÍTKOVINY</span>
					</button>
				</div>
				<div className="TabsContainer__TabCont">
					{isActive && settings && samplers.filter((s: Settings_Sampler) => activeGroup?.includes(s.id)).map((s, i) => (
						<Button color="#FFFFFF"  key={i} className={`${activeSamplerId === s.id && 'Button--active'} Button--id-${s.id}`} onClick={() => onClick(s.id)}>
							<img src={`vzornik/${s.id}.png`} />
							<div className="text" >
								<div className="title">{s.title}</div>
								<div className="description">{s.description}</div>
							</div>
						</Button>
					))}
				</div>
			</div>
		</>
	);
}