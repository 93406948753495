import {configureStore, combineReducers, getDefaultMiddleware} from '@reduxjs/toolkit';
import {useDispatch} from 'react-redux';
import artReducer, {setLayerColor, setLayerMask, setLayerActive, addLayer, removeLayer, removeAllLayers, simulationCreated, simulationRemoved} from 'redux/artReducer';
import applicationReducer from './applicationReducer';
import undoable, {includeAction} from 'redux-undo';


const rootReducer = combineReducers({
	application: applicationReducer,
	art: undoable(artReducer, {
		limit: 20,
		ignoreInitialState: true,
		syncFilter: true,
		filter: includeAction([setLayerColor.type, setLayerMask.type, setLayerActive.type, addLayer.type, removeLayer.type, removeAllLayers.type, simulationCreated.type, simulationRemoved.type])
	})
});

const store = configureStore({
	reducer: rootReducer,
	middleware: getDefaultMiddleware({
		serializableCheck: false
	})
});

console.log("BEWARE - time to time turn ON serializable check!");

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>() // Export a hook that can be reused to resolve types

export default store;
