import Bounds from 'definitions/Bounds';
import Point from 'definitions/Point';
import {useEffect, useRef, useState} from 'react';


interface Props {
	getPoint: (e: MouseEvent) => Point,
	mouseEnd: (bounds: Bounds) => void,
	mouseMove: (point: Point, newPoint: Point) => void,
	canvasRef: React.RefObject<HTMLCanvasElement>
}

export default function MouseTool(props:Props) {
	const canvasRef = props.canvasRef;
	
	const [isDrag, setIsDrag] = useState(false);
	const [mousePos, setMousePos] = useState<Point>({x: 0, y: 0});
	const boundsRef = useRef<Bounds>({minX: 10000, minY: 10000, maxX: 0, maxY:0});
	
	const getNewBounds = (p: Point): Bounds => {
		let newBounds = {...boundsRef.current};
		newBounds.minX = Math.min(newBounds.minX, p.x - 15);
		newBounds.minY = Math.min(newBounds.minY, p.y - 15);
		newBounds.maxX = Math.max(newBounds.maxX, p.x + 15);
		newBounds.maxY = Math.max(newBounds.maxY, p.y + 15); //tlouskta cary
		
		return newBounds;
	}
	
	const mouseMove = (event: MouseEvent) => {
		if (isDrag) {
			const newPoint = props.getPoint(event)

			props.mouseMove(mousePos, newPoint);
			
			setMousePos(newPoint);

			boundsRef.current = getNewBounds(newPoint);
		}
	};
	
	const startDrag = (event:MouseEvent) => {
		console.log("start drag")
		const point = props.getPoint(event);
		
		boundsRef.current = {minX: 10000, minY: 10000, maxX: 0, maxY:0};
		
		setIsDrag(true);
		setMousePos(point);
	};
	
	const stopDrag = () => {
		if (!isDrag) return;
		
		setIsDrag(false);
		
		props.mouseEnd(boundsRef.current);
	};
	
	/***** add listeners *****/
	
	useEffect(() => {
		if (!canvasRef.current)	return;
		
		const canvas: HTMLCanvasElement = canvasRef.current;
		canvas.addEventListener('mousedown', startDrag);
		return () => { canvas.removeEventListener('mousedown', startDrag) };
	}, [canvasRef]);
	
	useEffect(() => {
		if (!canvasRef.current)	return;
		
		const canvas: HTMLCanvasElement = canvasRef.current;
		canvas.addEventListener('mousemove', mouseMove);
		return () => { canvas.removeEventListener('mousemove', mouseMove)	};
	}, [isDrag, mousePos]);
	
	useEffect(() => {
		if (!canvasRef.current) return;
		
		const canvas: HTMLCanvasElement = canvasRef.current;
		canvas.addEventListener('mouseup', stopDrag);
		canvas.addEventListener('mouseleave', stopDrag);
		return () => {
			canvas.removeEventListener('mouseup', stopDrag);
			canvas.removeEventListener('mouseleave', stopDrag);
		};
	}, [isDrag]);
	
	return null;
}