import Overlay from 'components/Overlay/Overlay';
import SimulationItem from 'components/SimulationList/SimulationItem';
import {Progress} from 'definitions/AppState';
import Simulation from 'definitions/Simulation';
import React, {ReactElement} from 'react';

interface Props {
	progresses: Progress[]
}

export default function ProgressBar(props: Props): ReactElement {
	const progress = props.progresses;
	
	return (
		<Overlay className="Loader ProgressBar" enableBgClick={false}>
			{progress.map((p: Progress, i) =>
				<div key={i} className="mb15">
					<div className="ProgressBar__Text">{p.text}</div>
					<div className="ProgressBar__Bar"><div className="ProgressBar__Bar__Inner" style={{width: Math.round(p.sent / p.total * 100)+'%'}}></div></div>
				</div>
			)}
			
			
		</Overlay>
	)
}