import {usePDF} from '@react-pdf/renderer';
import {PdfDocument} from 'components/PdfDocument';
import {findById, findIndexById} from 'definitions/Utils';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from 'redux/store';

export function PdfGenerator(): ReactElement {
	const id = useSelector((state: RootState) => state.application.id);
	const name = useSelector((state: RootState) => state.application.name);
	const settings = useSelector((state: RootState) => state.application.settings);
	const simulations = useSelector((state: RootState) => state.art.present.simulations);
	const activeSimulationId = useSelector((state: RootState) => state.art.present.activeSimulationId) ?? 0;
	const simulation = findById(activeSimulationId, simulations);
	const index = findIndexById(activeSimulationId, simulations) + 1;
	const canvas: HTMLCanvasElement = document.getElementById('canvasMain') as HTMLCanvasElement;
	const image:HTMLImageElement = document.getElementById('baseImage') as HTMLImageElement;
	const tempCanvas = document.createElement('canvas');
	const context = tempCanvas.getContext('2d') as CanvasRenderingContext2D;
	tempCanvas.width = canvas.width;
	tempCanvas.height = canvas.height;
	context.drawImage(image, 0, 0, canvas.width, canvas.height);
	context.drawImage(canvas, 0, 0, canvas.width, canvas.height);

	const pdfData = {id: id, name: name, samplers: settings?.samplers ?? [], simulation: simulation, simulationIndex: index, imgData: tempCanvas.toDataURL()};

	const dateString = (new Date()).toISOString().substring(0, 19);
	const [instance] = usePDF({ document: <PdfDocument {...pdfData} /> });

	if (instance.loading) return <div>Připravuje je protokol ve formátu PDF ...</div>;
	
	if (instance.error) return <div>Vytvoření protokolu selhalo: {instance.error}</div>;
	
	return (
		<>
			{instance.url && <div style={{textAlign: 'center'}}><div>Hotovo.</div><a className="Button Button--large" href={instance.url} download={`HET-CS-protokol-${dateString}.pdf`}>Uložit protokol</a></div>}
		</>
	);
}