import Point from 'definitions/Point';
import {useEffect, useState} from 'react';


interface Props {
	getPoint: (e: MouseEvent) => Point,
	canvasRef: React.RefObject<HTMLCanvasElement>
	artRef: React.RefObject<HTMLDivElement>
}

export default function MoveTool(props:Props) {
	const canvasRef = props.canvasRef;
	const artRef = props.artRef;
	
	const [isDrag, setIsDrag] = useState(false);
	const [mousePos, setMousePos] = useState<Point>({x: 0, y: 0});

	
	const mouseMove = (event: MouseEvent) => {
		if (isDrag) {
			if (!artRef.current)	return;
			const art = artRef.current;
			
			const newPoint = props.getPoint(event)

			const dX = mousePos.x - newPoint.x;
			const dY = mousePos.y - newPoint.y;
			
			art.scrollTop = art.scrollTop + dY;
			art.scrollLeft = art.scrollLeft + dX;
			
			setMousePos(newPoint);
		}
	};
	
	const startDrag = (event:MouseEvent) => {
		console.log("start drag")
		const point = props.getPoint(event);
		
		setIsDrag(true);
		setMousePos(point);
		
		if (!canvasRef.current)	return;
		canvasRef.current.style.cursor = 'grabbing';
	};
	
	const stopDrag = () => {
		if (!isDrag) return;
		
		setIsDrag(false);
		
		if (!canvasRef.current)	return;
		canvasRef.current.style.cursor = 'grab';
	};
	
	/***** add listeners *****/
	
	useEffect(() => {
		if (!canvasRef.current)	return;
		
		const canvas: HTMLCanvasElement = canvasRef.current;
		canvas.addEventListener('mousedown', startDrag);
		
		canvas.style.cursor = 'grab';
		
		return () => { canvas.removeEventListener('mousedown', startDrag) };
	}, [canvasRef]);
	
	useEffect(() => {
		if (!canvasRef.current)	return;
		
		const canvas: HTMLCanvasElement = canvasRef.current;
		canvas.addEventListener('mousemove', mouseMove);
		return () => { canvas.removeEventListener('mousemove', mouseMove)	};
	}, [isDrag, mousePos]);
	
	useEffect(() => {
		if (!canvasRef.current) return;
		
		const canvas: HTMLCanvasElement = canvasRef.current;
		canvas.addEventListener('mouseup', stopDrag);
		canvas.addEventListener('mouseleave', stopDrag);
		return () => {
			canvas.removeEventListener('mouseup', stopDrag);
			canvas.removeEventListener('mouseleave', stopDrag);
		};
	}, [isDrag]);
	
	return null;
}