import {Document, Page, Text, View, Link, Image, StyleSheet, Font} from "@react-pdf/renderer";
import {Settings_Sampler} from 'definitions/Settings';
import Simulation from 'definitions/Simulation';
import React, {ReactElement} from 'react';
import logo from 'scss/assets/HET_logo_RGB.png';
import logoMultimix from 'scss/assets/logo_Multimix_cmyk.png';
import fontReg from 'scss/assets/Roboto-Regular.ttf';
import fontBold from "scss/assets/Roboto-Bold.ttf";

Font.register({ family: 'Roboto', fonts: [
		{ src: fontReg },
		{ src: fontBold, fontWeight: 500 },
	]});

const styles = StyleSheet.create({
	pageCol: {
		flexDirection: 'column',
		backgroundColor: 'white',
		padding: 30,
		fontFamily: 'Roboto',
		fontSize: 12
	}
});

interface Props {
	id?: string,
	name?: string,
	samplers: Settings_Sampler[],
	simulation: Simulation,
	imgData: string,
	simulationIndex: number
}

export function PdfDocument(props: Props): ReactElement {
	const layers = props.simulation.layers;
	
	return (
		<Document>
			<Page size="A4" style={styles.pageCol}>
				<View style={{fontSize: 10, flexDirection: 'row', marginBottom: 20}} fixed>
					<View style={{flex: '1 1 50%'}}>
						<Text style={{fontWeight: 500, marginBottom: 10}}>www.color-simulator.cz</Text>
						<Text style={{fontSize: 9}}>Barevné simulace jsou pouze orientační, přesný odstín </Text>
						<Text style={{fontSize: 9}}>vždy vybírejte podle reálného vzorníku u Vašeho prodejce nátěrových hmot HET.</Text>
						<View style={{fontSize: 9, display: 'flex', flexDirection: 'row'}}><Text >Seznam prodejců </Text><Link src="https://www.het.cz" style={{fontWeight: 500}}>www.het.cz</Link></View>
					</View>
					<View style={{alignSelf: 'flex-end'}}>
						<Image src={logo} style={{width: 96, height: 40, marginBottom: 20}}/>
						<Image src={logoMultimix} style={{width: 96, height: 18}}/>
					</View>
					
				</View>
				<View style={{textAlign: 'left'}}>
					<Text style={{fontWeight: 'bold'}}>{props.name && props.name}</Text>
					<Text>{props.id && `Projekt ID: ${props.id}`}</Text>
				</View>
				<Image src={props.imgData} style={{marginVertical: 15}} />
				<View style={{marginBottom: 15}}>
					{layers.map((layer, i) => (
						<View style={{marginBottom: 10, padding: 5, backgroundColor: 'rgb(237, 238, 241)',  flexDirection: 'row'}} key={i}>
							<View style={{flex: 1, justifyContent: 'center'}}>
								<Text style={{fontSize: 11, marginBottom: 5}}>{layer.name}</Text>
								{layer.color && <Text style={{fontWeight: 'bold'}}>{props.samplers.find((sam: Settings_Sampler) => sam.id === layer.samplerId)?.title} | {layer.color.name}</Text>}
							</View>
							{layer.color && <View style={{width: 50, alignItems: 'center', justifyContent: 'center'}}>
								<View style={{backgroundColor: `rgb(${layer.color.r}, ${layer.color.g}, ${layer.color.b})`, width: 50, height: 25}} ></View>
							</View>}
						</View>
					))}
				</View>
			</Page>
		</Document>
	);

}
