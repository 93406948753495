import {ActionCreatorWithPayload} from '@reduxjs/toolkit';
import {ReactElement, useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';

interface Props {
	tool: string,
	type: string,
	handler: ActionCreatorWithPayload<number>,
	isActive?: boolean,
	currentValue: number,
	max: number
}


export default function ToolSetting(props: React.PropsWithChildren<Props>): ReactElement {
	const dispatch = useDispatch();
	const sliderButtonRef = useRef<HTMLDivElement>(null);
	const sliderRef = useRef<HTMLDivElement>(null);
	const lineRef = useRef<HTMLDivElement>(null);
	const [isDrag, setIsDrag] = useState(false);
	const [xPos, setXPos] = useState(0);
	const [mousePos, setMousePos] = useState(0);
	const [width, setWidth] = useState(0);
	
	const startDrag = () => {
		setIsDrag(true);
	}
	
	const move = (event: MouseEvent) => {
		if (isDrag) {
			const line = lineRef.current;
			if (!line) return;
			
			const rect = line.getBoundingClientRect();
			const x = event.clientX - rect.left;
			const newXPos = Math.max(0, Math.min(width, x));
			
			setMousePos(event.clientX);
			setXPos(newXPos);
			
			dispatch(props.handler(Math.round(newXPos / width * props.max)));
		}
	}
	
	const stopDrag = () => {
		if (!isDrag) return;
		
		setIsDrag(false);
	};

	useEffect(() => {
		const sliderButton = sliderButtonRef.current;
		const sliderLine = lineRef.current;
		if (!sliderButton || !sliderLine) return;
		
		setWidth(sliderLine.clientWidth);
		setXPos(props.currentValue * width / props.max);
		
		sliderButton.addEventListener('mousedown', startDrag);
		return () => { sliderButton.removeEventListener('mousedown', startDrag) };
	},
		[width]
	);
	
	useEffect(() => {
		document.addEventListener('mousemove', move);
		return () => {
			document.removeEventListener('mousemove', move);
		};
	},
		[isDrag, mousePos]
	);
	
	useEffect(() => {
		document.addEventListener('mouseup', stopDrag);
		return () => {
			document.addEventListener('mouseup', stopDrag);
		};
	},
		[isDrag]
	);
	
	return (
		<div className={`ToolSetting ToolSetting--${props.tool} ToolSetting--${props.isActive ? 'active' : ''}`} >
			<div className="ToolSetting__Value">
				{props.type === 'circle' && <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width={`${props.currentValue}`} height={`${props.currentValue}`}><circle cx={props.currentValue / 2} cy={props.currentValue / 2} r={props.currentValue / 2} strokeWidth="1" style={{stroke: 'black', fill: 'none'}} /></svg>}
				{props.type === 'value' && <span>{props.currentValue}</span>}
			</div>
			<div className="ToolSetting__Slider" ref={sliderRef}>
				<div className="ToolSetting__Slider__Line" ref={lineRef} />
				<div className="ToolSetting__Slider__Button" ref={sliderButtonRef} style={{left: xPos - 7}} />
			</div>
		</div>
	)
}