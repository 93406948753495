import {findById} from 'definitions/Utils';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {RootState, useAppDispatch} from 'redux/store';
import {setMoveMode} from 'redux/artReducer';
import ico_move from "scss/assets/ico_hand.png"
import ico_move_white from "scss/assets/ico_hand_white.png"

interface Props {
}

export function MoveMenu (props: Props) {
	const dispatch = useAppDispatch();
	//const [isActive, setIsActive] = useState(false);
	const moveMode = useSelector((state: RootState) => state.art.present.moveMode);
	
	const artSize = useSelector((state: RootState) => state.application.artSize);
	const simulations = useSelector((state: RootState) => state.art.present.simulations);
	const activeSimulationId = useSelector((state: RootState) => state.art.present.activeSimulationId);

	
	const move = () => {
		//setIsActive(!isActive);
		dispatch(setMoveMode(!moveMode))
	}
	
	const ico = moveMode ? <img src={ico_move_white} /> : <img src={ico_move} />
	
	return (
		<div className="AppPanel__MoveMenu">
			<button className={`ReliefButton AppPanel__MoveMenu__Button ${moveMode ? 'ReliefButton--active' : ''}`} onClick={move}>{ico}</button>
		</div>
	)
}
