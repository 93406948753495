import {PdfGenerator} from 'components/AppPanel/PdfGenerator';
import {ProjectMenuItem} from 'components/AppPanel/ProjectMenuItem';
import {Button} from 'components/Button';
import Help from 'components/Help';
import ImgUpload from 'components/Homepage/ImgUpload';
import MessageOverlay from 'components/Overlay/MessageOverlay';
import {AppMode} from 'definitions/AppState';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {saveProject, saveProjectAsNew, loadProject, setIsPreparedProjectsListOpen, setProjectEmail, shareToEmail} from 'redux/applicationReducer';
import {RootState, useAppDispatch} from 'redux/store';
import ico_open from 'scss/assets/open_project_04.svg';
import ico_save from 'scss/assets/ico_project_save.svg';
import ico_share from 'scss/assets/ico_project_share.svg';
import ico_openPlus from 'scss/assets/ico_project_open+.png';

interface Props {
}

const SAVE_METHOD_SAVE = 'save';
const SAVE_METHOD_SAVE_AS_NEW = 'saveAsNew';

export function ProjectMenu (props: Props) {
	const isServerAvailable = useSelector((state: RootState) => state.application.isServerAvailable);
	const hasSimulations = useSelector((state: RootState) => state.application.hasSimulations);
	const isSaving = useSelector((state: RootState) => state.application.isSaving);
	const appMode = useSelector((state: RootState) => state.application.mode);
	const email = useSelector((state: RootState) => state.application.email);
	
	const [isLoadOverlayOpen, setIsLoadOverlayOpen] = useState(false);
	const [isPdfOverlayOpen, setIsPdfOverlayOpen] = useState(false);
	const [isSaveOverlayOpen, setIsSaveOverlayOpen] = useState<string|null>(null);
	const [isEmailOverlayOpen, setIsEmailOverlayOpen] = useState(false);
	
	const dispatch = useAppDispatch();
	
	const dateString = (new Date()).toISOString().substring(0, 19);
	
	const exportImg = (event: React.MouseEvent<HTMLAnchorElement>) => {
		const canvas: HTMLCanvasElement = document.getElementById('canvasMain') as HTMLCanvasElement;
		const tempCanvas = document.createElement('canvas');
		const image = document.getElementById('baseImage') as HTMLImageElement;
		const context = tempCanvas.getContext('2d');
		if (!image || !context) return;

		tempCanvas.width = canvas.width;
		tempCanvas.height = canvas.height;

		context.drawImage(image, 0, 0, canvas.width, canvas.height);
		context.drawImage(canvas, 0, 0, canvas.width, canvas.height);

		const d = tempCanvas.toDataURL();
		event.currentTarget.href = d;
	}
	
	const loadProjectById = (event: React.MouseEvent<HTMLElement>) => {
		const input = document.getElementById('projectid') as HTMLInputElement;
		const val = input.value;
		if (!val) return;
		dispatch(loadProject(val));
		setIsLoadOverlayOpen(false);
	}
	
	/*** SAVE ***/
	const setEmailAndSave = (event: React.MouseEvent<HTMLElement>) => {
		const input = document.getElementById('email') as HTMLInputElement;
		const val = input.value;
		if (val) {
			dispatch(setProjectEmail(val));
		}
		
		continueSave(isSaveOverlayOpen);
		
		setIsSaveOverlayOpen(null);
	}
	
	const save = (method: string) => {
		if (!email) {
			setIsSaveOverlayOpen(method);
			return;
		}
		
		continueSave(method);
	}
	
	const continueSave = (method: string|null) => {
		if (method === SAVE_METHOD_SAVE) {
			dispatch(saveProject());
		}
		else if (method === SAVE_METHOD_SAVE_AS_NEW) {
			dispatch(saveProjectAsNew());
		}
	}
	
	/*** SHARE ***/
	const share2Email = (event: React.MouseEvent<HTMLElement>) => {
		const input = document.getElementById('emailshare') as HTMLInputElement;
		const val = input.value;
		if (!val) return;
		dispatch(shareToEmail(val));
		setIsEmailOverlayOpen(false);
	}
	
	// <li className="border"><label>Přidat novou vlastní fotografii<ImgUpload /></label></li>
	return (
		<div className="AppPanel__ProjectMenu">
			<Help />
			<ProjectMenuItem className="" buttonLabel="Nový" buttonIcon={<img src={ico_openPlus} alt="nový" />}>
				<ul>
					<li><a href=".">Začít nový projekt</a></li>
					<li><button onClickCapture={() => setIsLoadOverlayOpen(true)}>Otevřít uložený projekt</button></li>
					<li><button onClickCapture={() => dispatch(setIsPreparedProjectsListOpen(true))}>Vybrat z připravených projektů</button></li>
				</ul>
			</ProjectMenuItem>
			
			<ProjectMenuItem className="" buttonLabel="Sdílet" buttonIcon={<img src={ico_share} alt="Sdílet" />} disabled={!hasSimulations}>
				<ul>
					<li><button onClickCapture={() => setIsEmailOverlayOpen(true)}>Sdílet na mail</button></li>
				</ul>
			</ProjectMenuItem>
			
			<ProjectMenuItem className="" buttonLabel="Uložit" buttonIcon={<img src={ico_save} alt="Uložit" />} disabled={!hasSimulations} closeOnClick={true}>
				<ul>
					<li>{(isServerAvailable && !isSaving && appMode === AppMode.default) && <button onClickCapture={() => save(SAVE_METHOD_SAVE)}>Uložit projekt</button>}
							{(!isServerAvailable || isSaving || appMode === AppMode.prepared) && <span className="disabled" >Uložit projekt</span>}
					</li>
					<li>{(isServerAvailable && !isSaving) && <button onClickCapture={() => save(SAVE_METHOD_SAVE_AS_NEW)}>Uložit jako nový</button>}
						{(!isServerAvailable || isSaving) && <span className="disabled" >Uložit jako nový</span>}
					</li>
					<li><a href="#" download={`colorsimulator_${dateString}.png`} onClickCapture={exportImg}>Exportovat obrázek</a></li>
					<li><button onClickCapture={() => setIsPdfOverlayOpen(true)}>Exportovat PDF protokol</button></li>
				</ul>
			</ProjectMenuItem>
			
			{isLoadOverlayOpen && <MessageOverlay className="MessageOverlay--load" enableBgClick={false} onClose={() => setIsLoadOverlayOpen(false)} >
			  <div className="header">
		      <img src={ico_open} alt="načíst"/>
			    <h3>Otevřít uložený projekt</h3>
	      </div>
			  <div className="inputCont">
					  <input type="text" name="projectid" id="projectid" placeholder="Zadejte ID projektu"/> <Button className="Button--small" onClickCapture={loadProjectById}>Otevřít</Button>
			  </div>
		  </MessageOverlay>
			}
			
			{isPdfOverlayOpen && <MessageOverlay className="MessageOverlay--pdf" enableBgClick={true} onClose={() => setIsPdfOverlayOpen(false)} >
				<PdfGenerator />
		  </MessageOverlay>
			}
			
			{isSaveOverlayOpen && <MessageOverlay className="MessageOverlay--email" enableBgClick={false} onClose={() => setIsSaveOverlayOpen(null)} >
		    <div className="header">
					<img src={ico_save} alt="ulozit"/>
			    <h3>Chcete dostat potvrzení a ID projektu emailem?</h3>
		    </div>
		    <div className="inputCont">
					<input type="email" name="email" id="email" placeholder="Vyplńte Vaši emailovou adresu"/>
			  </div>
			  <div className="buttonCont">
				  <Button className="Button--small" onClickCapture={setEmailAndSave}>Pokračovat</Button>
			  </div>
		  </MessageOverlay>
			}
			
			{isEmailOverlayOpen && <MessageOverlay className="MessageOverlay--emailshare" enableBgClick={false} onClose={() => setIsEmailOverlayOpen(false)} >
		  <div className="header">
			  <img src={ico_share} alt="sdilet"/>
			  <h3>Odeslat náhledy na email</h3>
		  </div>
		  <div className="inputCont">
			  <input type="email" name="emailshare" id="emailshare" placeholder="Zadejte emailovou adresu"/> <Button className="Button--small" onClickCapture={share2Email}>Odeslat</Button>
		  </div>
	  </MessageOverlay>
			}
		</div>
	)
}