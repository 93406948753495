import ScrollButton from 'components/SimulationList/ScrollButton';
import SimulationItem from 'components/SimulationList/SimulationItem';
import Simulation from 'definitions/Simulation';
import {ReactElement, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from 'redux/store';

interface Props {
}

export default function SimulationList(props:Props): ReactElement|null {
	console.log('SimulationList');
	
	const simulations = useSelector((state:RootState) => state.art.present.simulations);
	const activeId = useSelector((state:RootState) => state.art.present.activeSimulationId);
	const isOpen = useSelector((state:RootState) => state.application.isSimulationListOpen);
	const contRef = useRef<HTMLDivElement>(null);
	const scrollContRef = useRef<HTMLDivElement>(null);
	const [isScrollRight, setIsScrollRight] = useState(false);
	const [isScrollLeft, setIsScrollLeft] = useState(false);
	const [isOpenState, setIsOpenState] = useState(false);
	
	/*** remove completelly when hidden, to be saving compute power ***/
	const onTransitionEnd = () => {
		setIsOpenState(isOpen);
	}
	useEffect(() => {
		if (isOpen) setIsOpenState(isOpen);
	}, [isOpen]);
	
	/*** Scroll ***/
	//TODO dodelat drag to scroll  https://htmldom.dev/drag-to-scroll/
	const onChange = () => {
		const cont = contRef.current;
		const contScroll = scrollContRef.current;
		if (!cont || !contScroll) return;
		
		if (cont.scrollWidth < contScroll.scrollWidth) {
			setIsScrollRight(contScroll.scrollLeft < contScroll.scrollWidth - cont.scrollWidth);
			setIsScrollLeft(contScroll.scrollLeft > 0);
		}
		else {
			setIsScrollRight(false);
			setIsScrollLeft(false);
		}
	}
	
	useEffect(() => {
		onChange();
	}, [simulations]);
	
	const onClickScroll = (dir: number) => {
		const contScroll = scrollContRef.current;
		if (!contScroll) return;
		
		contScroll.scrollLeft += dir * 200;
	}
	
	return (!isOpen && !isOpenState) ? null : (
		<div className={`SimulationList ${(isOpen && isOpenState) ? 'SimulationList--open' : ''}`} onTransitionEnd={onTransitionEnd}>
			<div className="SimulationList__inner">
				<ScrollButton side={'left'} hidden={!isScrollLeft} onClick={() => {onClickScroll(-1)}} />
				<div className="SimulationList__Container" ref={contRef}>
					<div className="SimulationList__Container__scroll" ref={scrollContRef} onScroll={() => {onChange()}}>
						{simulations && simulations.map((sim: Simulation, i) => <SimulationItem key={i} simulation={sim} isActive={activeId === sim.id} />)}
					</div>
				</div>
				<ScrollButton side={'right'} hidden={!isScrollRight} onClick={() => {onClickScroll(1)}} />
			</div>
		</div>
	)
}