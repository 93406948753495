import {Button} from 'components/Button';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'redux/store';
import {toggleSimulationList} from 'redux/applicationReducer';

interface Props {
}

export function SimulationsButton (props: Props) {
	const simulations = useSelector((state: RootState) => state.art.present.simulations);
	const dispatch = useDispatch();
	
	const onClick = (e: React.MouseEvent<HTMLElement>) => {
		dispatch(toggleSimulationList());
	}
	
	return (
		<div className="AppPanel__SimulationsButton">
			<Button disabled={!simulations || !simulations.length} onClick={onClick} title="Varianty projektu">
				<img src="ico_simul_list.svg" alt="open simulations list" />
			</Button>
		</div>
	);

}