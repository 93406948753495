import {Button} from 'components/Button';
import {ReactElement} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {openColorSamplerList} from 'redux/artReducer';
import {RootState} from 'redux/store';

interface Props {
}

export default function ColorSamplerSelect(props:Props): ReactElement | null {
	const dispatch = useDispatch();
	const samplers = useSelector((state: RootState) => state.application.settings?.samplers);
	const samplerId = useSelector((state: RootState) => state.art.present.samplerId);
	const samplerItem = samplers?.find((sam) => sam.id === samplerId);
	
	const onClick = (e: React.MouseEvent<HTMLElement>) => {
		dispatch(openColorSamplerList());
	}
	
	return !samplers ? null : (
			<div className="ColorSamplerSelect">
					<Button className="ColorSamplerSelect__Button Button--large"
					        onClick={onClick}
					        color="white"
					        leftIcon={<img src="ico_sampler.png" alt="Volba vzorniku"/>}
					>{samplerItem?.title ?? 'Vybrat vzorník'}</Button>
			</div>
	)
}