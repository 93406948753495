
interface Props {
	isOn?: boolean
}

export default function IcoShadow(props: Props) {
	const isOn = props.isOn ?? true;
	return (
		<svg className={`IcoShadow ${isOn ? 'IcoShadow--on' : ''}`} viewBox='0 0 30 30' xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" version="1.0">
			<g>
				<path d="m15,0.875c8.01105,0 14.5,6.43301 14.5,14.375c0,7.94199 -6.48895,14.375 -14.5,14.375l0,-28.75z" />
				<ellipse ry="14.375" rx="14.5" id="svg_6" cy="15" cx="15" />
			</g>
			<g className="IcoShadow__Cross">
				<line y2="21.52242" x2="21.7113" y1="8.12502" x1="8.2887" />
				<line y2="8.30129" x2="21.875" y1="21.87498" x1="8.125" />
			</g>
		</svg>
	);
}
