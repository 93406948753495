import {ReactElement} from 'react';
import {useAppDispatch} from 'redux/store';
import {createSimulation, simulationActivated} from 'redux/artReducer';

interface Props {
}

export default function ImgUpload(props:Props): ReactElement {
	const MAX_WIDTH = 1920;
	const MAX_HEIGHT = 1080;
	const dispatch = useAppDispatch();
	
	const changeHandler = (event:React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.files) {
			return;
		}
		
		let reader = new FileReader();
		reader.onload = (e: ProgressEvent<FileReader>) => {
			if (!e.target) {
				return;
			}
			
			const image = new Image();
			image.src = e.target.result as string;
			image.onload = () => {
				console.log(image.width, image.height);
				let w: number = image.width;
				let h: number = image.height;
				
				if (w > MAX_WIDTH) {
					w = MAX_WIDTH;
					h = MAX_WIDTH / image.width * image.height;
				}
				if (h > MAX_HEIGHT) {
					h = MAX_HEIGHT;
					w = MAX_HEIGHT / image.height * image.width;
				}
				
				w = Math.floor(w);
				h = Math.floor(h);
				
				console.log('new dim', w, h);
				const c = document.createElement('canvas');
				c.width = w;
				c.height = h;
				const cc = c.getContext('2d');
				
				if (!cc) return;
				
				cc.drawImage(image, 0, 0, w, h);
				//const imageResized = cc.getImageData(0, 0, w, h);
				
				const newIndex:number = dispatch(createSimulation(c.toDataURL(), w, h));
				
				dispatch(simulationActivated(newIndex));
			}
		};
		//reader.readAsBinaryString(event.target.files[0]);
		reader.readAsDataURL(event.target.files[0]);
	}
	
	return (
			<input type="file" onChange={changeHandler} />
	)
	
}