import {Button} from 'components/Button';
import IcoShadow from 'components/Walls/IcoShadow';
import Color from 'definitions/Color';
import {PaintMethod} from 'definitions/PaintMethod';
import React, {ButtonHTMLAttributes, ReactElement, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {removeLayer, setLayerDefaultColor, setLayerName, setLayerPaintMethod} from 'redux/artReducer';
import {RootState, useAppDispatch} from 'redux/store';

interface OwnProps {
	layerId: number,
	layerName?: string,
	isActive: boolean,
	isSingle: boolean,
	isDisabled: boolean,
	colorItem?: Color,
	paintMethod: PaintMethod,
	onClick?: React.MouseEventHandler<HTMLDivElement>,
	onMouseEnter?: React.MouseEventHandler<HTMLDivElement>,
	onMouseLeave?: React.MouseEventHandler<HTMLDivElement>,
}

type Props = OwnProps & ButtonHTMLAttributes<HTMLButtonElement>

export default function Wall(props:Props): ReactElement {
	const {colorItem, className, onClick, onMouseEnter, onMouseLeave, layerId, layerName, isActive, isSingle, isDisabled, paintMethod} = props;
	
	const samplerId = useSelector((state:RootState) => state.art.present.samplerId);
	
	const color = colorItem ? `rgb(${colorItem.r}, ${colorItem.g}, ${colorItem.b})` : 'transparent';
	
	const dispatch = useAppDispatch();
	const [isEdit, setIsEdit] = useState(false);
	
	console.log('wall', colorItem, samplerId, paintMethod)
	
	const computeTextColor = (color: Color | undefined): string => {
		if (!color) return 'black';
		
		const sum = color.r + color.g + color.b;
		return sum > 450 ? 'black' : 'white';
	}
	
	const setName = () => {
		const input = document.getElementById('layerName') as HTMLInputElement;
		const val = input.value;
		if (val) {
			dispatch(setLayerName({layerId: layerId, name: val}));
		}
		setIsEdit(false);
	}
	
	const onClickRemove = () => {
		dispatch(removeLayer(layerId));
	}
	
	const onClickEdit = () => {
		if (isDisabled) {
			return;
		}
		setIsEdit (true);
	}

	const changePaintMethod = () => {
		dispatch(setLayerPaintMethod({
			layerId: layerId,
			paintMethod: paintMethod === PaintMethod.INTERPOLATE ? PaintMethod.COLOR : PaintMethod.INTERPOLATE,
		}));
	}
	
	useEffect(() => {
		if (!colorItem && samplerId) {
			console.log('set DEFAULT')
			dispatch(setLayerDefaultColor());
		}
	})
	useEffect(() => {
		const input = document.getElementById('layerName') as HTMLInputElement;
		if (input) {
			input.focus();
		}
	})
	
	let nameElm;
	if (isEdit) {
		nameElm = <span className="Wall__Panel__Name__Input"><input id="layerName" type="text" defaultValue={layerName} onBlur={setName} /><Button className="Button--small" onClickCapture={setName}>OK</Button></span>;
	}
	else {
		nameElm = <a className="Wall__Panel__Name__Placeholder" onClick={onClickEdit}>{layerName || 'Bez názvu...'}</a>;
	}
	
	return (
		<div className={`ReliefButton Wall ${className || ''} ${isActive ? 'ReliefButton--active' : ''} ${isEdit ? 'Wall--edit' : ''}`} id={layerId.toString()}>
			{!isSingle && !isDisabled &&
				<Button className="Wall__Button Wall__Button--delete Button--small" color={'white'} onClick={onClickRemove} title="smazat vrstvu">
					<svg width="15" height="15"><use xlinkHref="#ico_trash" /></svg>
		    </Button>
			}
			{!isDisabled &&
				<Button className="Wall__Button Wall__Button--shadow Button--small" color={'white'} onClick={changePaintMethod} title='stíny'>
					<IcoShadow isOn={paintMethod === PaintMethod.INTERPOLATE}/>
				</Button>
			}

			<div className="Wall__Panel" onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
				<div className="Wall__Panel__Name">{nameElm}</div>
				
				{!isEdit && <div>
					<div className="Wall__Panel__Color" style={{backgroundColor: color, color: computeTextColor(colorItem)}}>
						<span className="Wall__Panel__Color__Title">{colorItem?.name || <span className="Wall__Panel__Color__Title__Placeholder">Bez barvy...</span>}</span>
					</div>
				</div>}
			</div>
		</div>
	)
}