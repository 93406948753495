import {ReactElement} from 'react';

interface Props {}

export default function YoutubeTutorial(props: Props): ReactElement {
	
	return (
		<div className="VideoTutorial">
			<iframe width="854" height="480" src="https://www.youtube.com/embed/rszj8nS7NPA" title="HET ColorSimulator tutorial" frameBorder="0"
			        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{verticalAlign: 'top'}}>
			</iframe>
		</div>
	)
}