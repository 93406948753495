import {DrawingTools} from 'components/DrawingTools/DrawingTools';
import {Walls} from 'components/Walls/Walls';
import React, {ReactElement} from 'react';

interface Props {
	samplerId?: string|null
}

export default function LeftContainer(props:Props): ReactElement {
	return (
			<div className="LeftContainer">
				<DrawingTools isDisabled={!props.samplerId} />
				
				<Walls isDisabled={!props.samplerId} />
			</div>
	)
}