import Bounds from 'definitions/Bounds';
import Color from 'definitions/Color';
import Mask, {concatMasks} from 'definitions/Mask';
import Point from 'definitions/Point';
import {findById} from 'definitions/Utils';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {setLayerMask} from 'redux/artReducer';
import {RootState, useAppDispatch} from 'redux/store';

import {floodFillWithBorders, ImageFloodFillObject} from 'definitions/FloodFill';

const MagicWand = require('magic-wand-tool');


interface Props {
	width: number,
	height: number,
	canvasDataInitialRef: React.RefObject<ImageData|null>,
	color: Color,
	mask: Mask|null,
	getPoint: (e: MouseEvent) => Point,
	saveCanvas: (bounds: Bounds) => void,
	canvasDrawRef: React.RefObject<HTMLCanvasElement>
}

export default function Wand(props:Props) {
	console.log("Wand"); //TODO dodelat change setting na drag, viz ofic. example
	
	const settingWand = useSelector((state: RootState) => state.application.settingWand);
	const simulations = useSelector((state: RootState) => state.art.present.simulations);
	const activeSimulationId = useSelector((state: RootState) => state.art.present.activeSimulationId);
	const layers = findById(activeSimulationId ?? 0, simulations).layers;
	const dispatch = useAppDispatch();
	
	const canvasRef = props.canvasDrawRef;
	
	const paintMask = (event: MouseEvent) => {
		console.log("paint")
		if (!canvasRef.current || !props.canvasDataInitialRef.current) return;

		const canvas: HTMLCanvasElement = canvasRef.current;
		const context = canvas.getContext('2d');
		if (!context) return;

		const point = props.getPoint(event);

		const image:ImageFloodFillObject = {
			data: props.canvasDataInitialRef.current.data,
			width: canvas.width,
			height: canvas.height,
			bytes: 4
		};
		
		let completeMask: Mask|null = null;
		for (let i:number = 0; i < layers.length; i++) {
			let layer = layers[i];
			if (layer.mask) {
				completeMask = concatMasks(layer.mask, completeMask);
			}
		}
		
		const blurRadius = 2;

		let newMask = floodFillWithBorders(image, point.x, point.y, settingWand, completeMask);
		if (newMask) {
			newMask = MagicWand.gaussBlurOnlyBorder(newMask, blurRadius, completeMask ? completeMask.data : null);
		}

		if (!newMask) return;
		
		const mask = concatMasks(newMask, props.mask);
		dispatch(setLayerMask(mask));
	}
	
	/***** add listeners *****/
	
	useEffect(() => {
		if (!canvasRef.current)	return;
		
		const canvas: HTMLCanvasElement = canvasRef.current;
		canvas.addEventListener('mousedown', paintMask);
		return () => { canvas.removeEventListener('mousedown', paintMask) };
	}, [paintMask, canvasRef]);
	
	useEffect(() => {
		const canvas = canvasRef.current;
		
		if (!canvas) return;
		canvas.style.cursor = `auto`;
	}, [])
	
	
	return null;
}