import Color from 'definitions/Color';

export default interface Sampler {
	colors: Color[]
}

export function getDefaultColor(sampler?: Sampler): Color | undefined {
	if (!sampler) return undefined;
	
	return sampler.colors[0];
}