import Art from 'components/Art/Art';
import SimulationList from 'components/SimulationList/SimulationList';
import React, {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from 'redux/store';

export default function MainContainer(): ReactElement {
	const activeSimulationId = useSelector((state: RootState) => state.art.present.activeSimulationId);
	const activeLayerId =      useSelector((state: RootState) => state.art.present.activeLayerId);
	
	return (
		<div className="MainContainer">
			<SimulationList />
			
			{activeSimulationId && activeLayerId && <Art activeSimulationId={activeSimulationId} activeLayerId={activeLayerId} />}
		</div>
	)
}