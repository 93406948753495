import {Button} from 'components/Button';
import ImgUpload from 'components/Homepage/ImgUpload';
import React, {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {loadProject, setIsPreparedProjectsListOpen, setIsHelpOpen} from 'redux/applicationReducer';
import {RootState, useAppDispatch} from 'redux/store';
import ico_upload from 'scss/assets/upload_photo_04.svg';
import ico_prepared from 'scss/assets/open_gallery_04.svg';
import ico_open from 'scss/assets/open_project_04.svg';
import ico_help from 'scss/assets/ico_help.svg';

interface Props {}

export default function Homepage(props: Props): ReactElement {
	const settings =  useSelector((state: RootState) => state.application.settings);
	
	const dispatch = useAppDispatch();
	
	const loadProjectById = (event: React.MouseEvent<HTMLElement>) => {
		const input = document.getElementById('projectid') as HTMLInputElement;
		const val = input.value;
		if (!val) return;
		dispatch(loadProject(val));
	}
	
	const openPreparedList = () => {
		dispatch(setIsPreparedProjectsListOpen(true));
	}
	
	return (
		<div className="Homepage">
			<div className="Homepage__Header">
				<h1>HET ColorSimulator 3.0</h1>
			</div>
			
			<div className="Homepage__Row__Container">
				{settings &&
				  <div className="Homepage__Row__Container__Section Homepage__Row__Container__Section--new">
					  <label>
				      <ImgUpload/>
					    <img src={ico_upload} alt="vlastní projekt"/>
						  <h3>Projekt z vlastní fotografie</h3>
							<p>Začněte nahráním fotografie. Klikněte nebo přetáhněte sem.</p>
					  </label>
				  </div>
				}
	
				{settings &&
				  <div className="Homepage__Row__Container__Section Homepage__Row__Container__Section--prepared" onClick={openPreparedList}>
				    <img src={ico_prepared} alt="připravené projekty"/>
					  <h3>Galerie vzorových projektů</h3>
						<p>Vyzkoušejte si barevné kombinace s využitím připravených fotografií.</p>
				  </div>
				}
				
				{settings &&
					<div className="Homepage__Row__Container__Section Homepage__Row__Container__Section--load">
						<img src={ico_open} className="IcoOpen" alt="načíst"/>
						<h3>Otevřít uložený projekt</h3>
						<input type="text" id="projectid" placeholder="Zadejte ID projektu"/> <Button className="Button--small" onClick={loadProjectById}>Otevřít</Button>
					</div>
				}
			</div>
			<div className="Homepage__Row__Container">
				{settings &&
					<div className="Homepage__Row__Container__Section Homepage__Row__Container__Section--help" onClick={() => {dispatch(setIsHelpOpen(true))}}>
					  <img src={ico_help} alt="Video-návod"/>
					  <h3>Instruktážní video</h3>
			      <p>Naučte se pracovat s&nbsp;Colorsimulatorem.</p>
					</div>
				}
			</div>
		</div>
	)
}