import {Component, ButtonHTMLAttributes, ReactNode} from "react";

interface OwnProps {
	rightIcon?: ReactNode,
	leftIcon?: ReactNode,
	color?: string,
	onClick?: React.MouseEventHandler<HTMLElement>,
}

type Props = OwnProps & ButtonHTMLAttributes<HTMLButtonElement>

export class MenuButton extends Component<Props> {
	public render = () => {
		const {rightIcon, leftIcon, className, color, onClick, ...props} = this.props;
		
		let style: React.CSSProperties = props.style || {};
		
		if (color) {
			style.backgroundColor = color;
		}
		
		return (
			<button type="button" className={`MenuButton ${className || ''} ${rightIcon ? 'MenuButton--rightIcon' : ''} ${leftIcon ? 'MenuButton--leftIcon' : ''}`}
			  onClick={onClick}
				style={style}
			  {...props}
			>
				{leftIcon && (
					<span className="MenuButton__icon MenuButton__icon--left">{leftIcon}</span>
				)}
				{this.props.children}
				{rightIcon && (
					<span className="MenuButton__icon MenuButton__icon--right">{rightIcon}</span>
				)}
			</button>
		);
	}
}
