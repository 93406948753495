import {Component, ButtonHTMLAttributes, ReactNode} from "react";

interface OwnProps {
	size?: 'small' | 'normal' | 'large',
	rightIcon?: ReactNode,
	leftIcon?: ReactNode,
	color?: string,
	onClick?: React.MouseEventHandler<HTMLElement>,
	onClickCapture?: React.MouseEventHandler<HTMLElement>,
}

type Props = OwnProps & ButtonHTMLAttributes<HTMLButtonElement>

export class Button extends Component<Props> {
	public render = () => {
		const {size, rightIcon, leftIcon, className, color, onClick, onClickCapture, ...props} = this.props;
		
		let style: React.CSSProperties = props.style || {};
		
		if (color) {
			style.backgroundColor = color;
		}
		
		return (
			<button type="button" className={`Button ${className || ''} ${size ? 'Button--'+size : ''} ${rightIcon ? 'Button--rightIcon' : ''} ${leftIcon ? 'Button--leftIcon' : ''}`}
			  onClick={onClick}
        onClickCapture={onClickCapture}
				style={style}
			  {...props}
			>
				{leftIcon && (
					<span className="Button__icon Button__icon--left">{leftIcon}</span>
				)}
				{this.props.children}
				{rightIcon && (
					<span className="Button__icon Button__icon--right">{rightIcon}</span>
				)}
			</button>
		);
	}
}
