import {ReactElement} from 'react';
import {useAppDispatch} from 'redux/store';
import ico_help from 'scss/assets/ico_help.svg';
import {setIsHelpOpen} from 'redux/applicationReducer';

interface Props {}

export default function Help(props: Props): ReactElement {
	const dispatch = useAppDispatch();
	
	return (
		<div className="Help" title="Video-návod" onClick={() => {dispatch(setIsHelpOpen(true))}}>
			<img src={ico_help} alt="nápověda" />
		</div>
	)
}