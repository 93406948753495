import {Button} from 'components/Button';
import Overlay from 'components/Overlay/Overlay';
import {HTMLAttributes, ReactElement} from 'react';

interface OwnProps{
	onClose?: () => void,
	enableBgClick?: boolean,
	overlayRef?: React.RefObject<HTMLDivElement>
}

type Props = OwnProps & HTMLAttributes<HTMLElement>

export default function MessageOverlay(props: Props): ReactElement {
	const {className, ...restProps} = props;
	const onClose = restProps.onClose;
	
	return <Overlay {...restProps} className={`MessageOverlay ${className}`} overlayRef={props.overlayRef}>
			<Button className="Button--close" onClick={onClose}>x</Button>
			{props.children}
		</Overlay>;
}