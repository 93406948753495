import {ActionCreatorWithPayload} from '@reduxjs/toolkit';
import ToolSetting from 'components/DrawingTools/ToolSetting';
import {ButtonHTMLAttributes, ReactElement, ReactNode} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'redux/store';
import {setTool} from 'redux/artReducer';

interface OwnProps {
	icon?: ReactNode,
	onClick?: () => void,
	settingType?: string,
	settingHandler?: ActionCreatorWithPayload<number>,
	currentValue?: number,
	max?: number
}

type Props = OwnProps & ButtonHTMLAttributes<HTMLButtonElement>

export default function Tool(props:Props): ReactElement {
	const dispatch = useDispatch();
	
	const {icon, onClick, className, id, settingType, settingHandler, max, currentValue, ...restProps} = props;
	const activeTool = useSelector((state: RootState) => state.art.present.tool);
	const isActive = activeTool === id;
	
	const onClickDefault = () => {
		dispatch(setTool(id));
	}
	
	return (
		<>
			<button className={`ReliefButton Tool ${className || ''} ${isActive ? 'ReliefButton--active' : ''}`} onClick={onClick ?? onClickDefault} {...restProps}>
				{props.children}
				<span className="ReliefButton__icon">{props.icon}</span>
			</button>
		
			{!props.disabled && settingType && settingHandler && <ToolSetting tool={id ?? ''} isActive={isActive} currentValue={currentValue ?? 0} type={settingType} max={max ?? 50} handler={settingHandler} />}
		</>
	)
}