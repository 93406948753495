
export function findById<T extends {id: number}>(id: number, items: T[]): T {
	return items.filter((item: T) => item.id === id)[0];
}

export function findIndexById<T extends {id: number}>(id: number, items: T[]): number {
	return items.findIndex((item: T) => item.id === id);
}

export function removeItemById<T extends {id: number|string}>(id: number|string, items: T[]): T[] {
	return items.filter((item: T) => item.id !== id);
}

export function parseImageHeader(imgData: string): string {
	const arr = imgData.split(',');
	return arr.length > 1 ? arr[0] : '';
}

export function getImageBlob(imgData: string): Blob
{
	const arr = imgData.split(',');
	const headerMatch = arr[0].match(/:(.*?);/) || [];
	const mime = headerMatch[1] || 'image/png';
	
	const	bstr = atob(arr[1]);
	let n = bstr.length,
		u8arr = new Uint8Array(n);
	
	while(n--){
		u8arr[n] = bstr.charCodeAt(n);
	}
	
	return new Blob([u8arr], {type: mime});
}
